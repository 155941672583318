import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { axiosClient } from '../constants/axionsClient';
import { Loading } from '.';
import { AnnonceBg } from '../constants/images'

const Product = ({item}) => {
    const { title, ref, category, price, photo } = item;
    return (
        <div key={ref} style={{width:'100%'}} className="owl-item">
            <div className="product type-product post-2928 status-publish outofstock product_cat-materiel-electrique-electronique-optique has-post-thumbnail featured shipping-taxable purchasable product-type-simple">
                <div className="product-outer product-item__outer">
                    <div className="product-inner product-item__inner">
                        
                        <div className="product-loop-header product-item__header">
                            <span className="loop-product-categories">
                                <Link to={`/product-category/${category}`} rel="tag">
                                {category}
                                </Link>
                            </span>
                            <Link
                                to={`/product/${ref}`}
                                className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                            >
                                <h2 className="woocommerce-loop-product__title">{title}</h2>
                                <div className="product-thumbnail product-item__thumbnail">
                                <img
                                    width="300"
                                    height="300"
                                    src={photo || AnnonceBg}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt={title}
                                />
                                </div>
                            </Link>
                        </div>

                        <div className="product-loop-footer product-item__footer">
                            <div className="price-add-to-cart">
                                <span className="price">
                                <span className="electro-price">
                                    <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                        {price}
                                        <span className="woocommerce-Price-currencySymbol">
                                            DH
                                        </span>
                                    </bdi>
                                    </span>
                                </span>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

const SwiperAnnonces = ({header, RequetsPath, setTopProductsCount = null}) => {
    const { t } = useTranslation();
    const [TotalPages, setTotalPages] = useState(2)
    const [IsLoading, setIsLoading] = useState(true)
    const [Annonces, setAnnonces] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const swiperRef = useRef(null);
    const mainRef = useRef(null);
    const [VisibleProducts, setVisibleProducts] = useState(2)

    const updateVisibleProducts = () => {
        if (window.innerWidth >= 1200) {
            setVisibleProducts(5);
        } else if (window.innerWidth >= 1029) {
            setVisibleProducts(3);
        } else {
            setVisibleProducts(2);
        }
    };

    useEffect(() => {

        if(Annonces?.length > 0){

            setTotalPages(Math.ceil(Annonces?.length / VisibleProducts));

        }

    }, [VisibleProducts, Annonces])

    useEffect(() => {
        
        updateVisibleProducts();

        window.addEventListener("resize", updateVisibleProducts);
        
        return () => window.removeEventListener("resize", updateVisibleProducts);
    }, []);

    useEffect(() => {
        (async()=>{

            try {
            
                const res = await axiosClient.get(RequetsPath)
        
                if(res.status === 200){
        
                    setAnnonces(res.data)
                    setTotalPages(Math.ceil(res?.data?.length / VisibleProducts ))
                    
                    if(res.data.length == 0){

                        mainRef.current.style.display = 'none'

                    }

                    if(setTopProductsCount){
                        setTopProductsCount(res.data.length)
                    }
        
                }
    
            } catch (error) {
                console.log(error);
            }
    
            setIsLoading(false)
    
        })()
    }, [])

    const goToPage = (page) => {
        setCurrentPage(page);
        if (swiperRef.current) {
            swiperRef.current.slideTo((page - 1) * VisibleProducts);
        }
    };

    const goToNextSlide = () => {
        if (swiperRef.current) swiperRef.current.slideNext();
    };
    
    const goToPrevSlide = () => {
        if (swiperRef.current) swiperRef.current.slidePrev();
    };

  return (
    <div ref={mainRef} className="vc_row wpb_row vc_row-fluid">
        <div style={{zIndex : '0'}} className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
                <div className="wpb_wrapper">
                    <section className="section-products-carousel">
                    <header>
                        <h2 className="h1">{header}</h2>
                        <div className="owl-nav">
                        <a
                            onClick={() => goToPrevSlide()}
                            data-target="#products-carousel-6720c7a0915ff"
                            className="slider-prev"
                        >
                            <i className="fa fa-angle-left"></i>
                        </a>
                        <a
                            onClick={goToNextSlide}
                            data-target="#products-carousel-6720c7a0915ff"
                            className="slider-next"
                        >
                            <i className="fa fa-angle-right"></i>
                        </a>
                        </div>
                    </header>

                    {
                        IsLoading ? (
                            <Loading style={{padding : '20px 0'}} />
                        )
                        : (
                            Annonces.length === 0 ? (
                                <p style={{textAlign: 'center'}}>{t('swiper_annonces.no_announces')}</p>
                            ) : (
                                <div
                                    id="products-carousel-6720c7a0915ff"
                                    data-ride="owl-carousel"
                                    data-replace-active-className="true"
                                    data-carousel-selector=".products-carousel"
                                >
                                    <div className="woocommerce columns-4">
                                        <div
                                            data-view="list-view"
                                            data-bs-toggle="shop-products"
                                            className="products owl-carousel products-carousel products list-unstyled row g-0 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 owl-loaded owl-drag"
                                        >
                                            <div className="owl-stage-outer">
                                                <div
                                                    className="owl-stage"
                                                >
            
                                                    <Swiper
                                                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                                                        slidesPerView={VisibleProducts}
                                                    >
                                                        <div style={{
                                                            display : 'inline-flex',
                                                            margin : '10px'
                                                        }}>
                                                            {
                                                                Annonces?.map(item => (
                                                                    <SwiperSlide><Product item={item}/></SwiperSlide>
                                                                ))
                                                            }
                                                        </div>
                                                    </Swiper>
            
                                                </div>
                                            </div>
                                            <div className="owl-nav disabled">
                                                <button
                                                    type="button"
                                                    onClick={() => goToPrevSlide()}
                                                    className="owl-prev"
                                                >
            
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={goToNextSlide}
                                                    className="owl-next"
                                                >
            
                                                </button>
                                            </div>
            
                                            <div className="owl-dots">
                                                {
                                                    Array.from({ length : TotalPages }, (_, index) => index + 1)?.map((_, index) => (
                                                        <button onClick={() => goToPage(index + 1)} role="button" className={`owl-dot ${index + 1 == currentPage ? 'active' : '' }`}>
                                                            <span></span>
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }

                    </section>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SwiperAnnonces