import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import { AidePage, AnnoncesOffresPage, AnnoncesSearchPage, AnnounceDetails, AproposNous, Avantages, CompaniesSeachPage, Conditions, Fournisseur, Fournisseurs, Home, Login, LoginWithToken, Plans, PlansPage, ProductCategory, Recrutement, Wishlist } from "./pages";
import Blog from "./components/Blogs/Blog";
import Blog1 from "./components/Blogs/Blog1";
import { baseRoute } from "./constants/env";

const router = createBrowserRouter([
	{
		path : '/auth/login',
		element : <Login />
	},
    {
        path: '/',
		element: <App />,
        children : [
			{
				path : '/',
				element : <Home />	
			},
			{
				path : '/annonces-offres',
				element : <AnnoncesOffresPage />	
			},
			{
				path : '/wishlist',
				element : <Wishlist />	
			},
			{
				path : '/product/:ref',
				element : <AnnounceDetails />
			},
            {
				path : '/product-category/:category/:subcategory?',
				element : <AnnoncesOffresPage />
			},
			{
				path : '/search-page/annonces/:query',
				element : <AnnoncesOffresPage />
			},
			{
				path : '/search-page/fournisseurs/:query',
				element : <Fournisseurs />
			},
			{
				path : '/fournisseurs/:number',
				element : <Fournisseur />
			},
			{
				path : '/fournisseurs',
				element : <Fournisseurs />
			},
			{
				path : '/login-with-session/:token',
				element : <LoginWithToken />
			},
			// static pages
			{
				path : '/avantages-de-vendre-sur-b2b-maroc',
				element : <Avantages />
			},
			{
				path : '/blog-et-conseils-daffaires',
				element : <Avantages />
			},
			{
				path : '/aide',
				element : <AidePage />
			},
			{
				path : '/a-propos-de-nous/',
				element : <AproposNous />
			},
			{
				path : '/conditions-generales-dutilisation/',
				element : <Conditions />
			},
			{
				path : '/recrutement/',
				element : <Recrutement />
			},
			{
				path:"/nos-packs-premium",
				element:<PlansPage/>
			},
			{
				path: '/blog',
				element: <Blog />,
			},
			{
				path: '/blog/:id',
				element: <Blog1 />,
			},
        ]
    },
	{
        path : '*',
        element : <Navigate to='/'/>
    }
],
    {
        // basename: '/',
        basename: baseRoute,
    }
);

export default router;