import React from 'react'
import { useTranslation } from 'react-i18next'

const ResultsInfo = ({
    currentPage,
    ItemsPerPage,
    TotalResults,
    annonces,
    totalPages,
  }) => {
  const { t } = useTranslation();

  return (
    <>
      {totalPages == 1 ? (
        <p className="woocommerce-result-count">
          {t('announces.results_info.showing', {
            start: 1,
            end: annonces?.length,
            total: TotalResults
          })}
        </p>
      ) : (
        <p className="woocommerce-result-count">
          {t('announces.results_info.showing', {
            start: (currentPage - 1) * ItemsPerPage + 1,
            end: Math.min(
              (currentPage - 1) * ItemsPerPage + 1 + annonces?.length - 1,
              TotalResults
            ),
            total: TotalResults
          })}
        </p>
      )}
    </>
  )
}

export default ResultsInfo