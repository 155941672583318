import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { axiosClient } from './../constants/axionsClient'
import toast from 'react-hot-toast'

const ActionListSouhaits = ({announce_ref, is_added_status}) => {
    const { t } = useTranslation();

    const [is_added, setIs_added] = useState(is_added_status)
  
    const addToWishlist = async (e) => {
  
      e.preventDefault()
      toast.loading(t('action_list_souhaits.loading'))
      try {
        
        const res = await axiosClient.post(`/wishlist/add/${announce_ref}`)
  
        if(res.status === 200){
          setIs_added(true)
          toast.dismiss()
          toast.success(t('action_list_souhaits.success_messages.added'))
        }
  
      } catch (error) {
        console.log(error);
        toast.dismiss()
        toast.error(t('action_list_souhaits.error_messages.not_added'))
      }
  
    }
  
    const removeFromWishlist = async (e) => {
  
      e.preventDefault()
      toast.loading(t('action_list_souhaits.loading'))
      try {
        
        const res = await axiosClient.post(`/wishlist/remove/${announce_ref}`, {
          _method : 'delete'
        })
  
        if(res.status === 200){
          setIs_added(false)
          toast.dismiss()
          toast.success(t('action_list_souhaits.success_messages.removed'))
        }
  
      } catch (error) {
        console.log(error);
        toast.dismiss()
        toast.error(t('action_list_souhaits.error_messages.not_removed'))
      }
  
    }
  
    return (
      <div className="action-buttons">
        <div className="yith-wcwl-add-to-wishlist add-to-wishlist-6963 yith-wcwl-add-to-wishlist--link-style yith-wcwl-add-to-wishlist--single wishlist-fragment on-first-load">
          <div className="yith-wcwl-add-button">
            <a
              onClick={
                is_added ? (e) => removeFromWishlist(e) : (e) => addToWishlist(e)
              }
              href="#"
              className="add_to_wishlist single_add_to_wishlist"
              dataTitle={is_added ? t('action_list_souhaits.remove_from_wishlist') : t('action_list_souhaits.add_to_wishlist')}
              rel="nofollow"
            >
              <svg
                id="yith-wcwl-icon-heart-outline"
                className="yith-wcwl-icon-svg"
                fill={is_added ? "currentColor" : "none"}
                style={{
                  height: "20px",
                }}
                strokeWidth="1.5"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                ></path>
              </svg>
              <span>
                {is_added ? t('action_list_souhaits.product_added') : t('action_list_souhaits.add_to_wishlist')}
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  
  }

export default ActionListSouhaits