import React, {useState} from "react";
import { axiosClient } from '../constants/axionsClient';
import { Link } from "react-router-dom";
import { B2BLogo, FooterBg } from "../constants/images";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const information = JSON.parse(localStorage.getItem("information-owner"))

    const hundelClick = async (event) => {
        event.preventDefault()
    
        if(email !== ''){
            toast.loading(t('toast.newsletter.sending'))
            try {
                const res = await axiosClient.post('/news-letters', {
                    email : email
                })
    
                if(res.status === 200){
                    toast.dismiss()
                    toast.success(t('toast.newsletter.success'))
                    setEmail('')
                }
    
            } catch (error) {
                if(error.response.status === 422){
                    toast.dismiss()
                    toast.error(t('toast.newsletter.error'))
                }
            }
        }
    }

    return (
        <footer id="colophon" className="site-footer footer-v2" style={{width: "100%"}}>

            <div className="desktop-footer d-none d-lg-block container">
                <div className="footer-newsletter">
                    <div className="container">
                        <div className="footer-newsletter-inner row">
                            <div className="newsletter-content col-lg-7">
                                <h5 className="newsletter-title"></h5>
                                <span className="newsletter-marketing-text">
                                    {t('newsletter.description')}
                                </span>
                            </div>
                            <div className="newsletter-form col-lg-5 align-self-center">
                                <div className="wpforms-container wpforms-container-full ec-newsletter-form" id="wpforms-5346">
                                    <form onSubmit={hundelClick} id="wpforms-form-5346" className="wpforms-validate wpforms-form">
                                        <noscript className="wpforms-error-noscript">
                                            {t('newsletter.enable_js')}
                                        </noscript>
                                        <div className="wpforms-field-container">
                                            <div id="wpforms-5346-field_1-container" className="wpforms-field wpforms-field-email" data-field-id="1">
                                                <label style={{display: "none"}} className="wpforms-field-label wpforms-label-hide" htmlFor="wpforms-5346-field_1">
                                                    {t('form.email')} <span className="wpforms-required-label">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    id="wpforms-5346-field_1"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="wpforms-field-large wpforms-field-required"
                                                    name="wpforms[fields][1]"
                                                    placeholder={t('newsletter.placeholder')}
                                                    spellCheck="false"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: "none"}} className="wpforms-field wpforms-field-hp">
                                            <label htmlFor="wpforms-5346-field-hp" className="wpforms-field-label">
                                                {t('form.name')}
                                            </label>
                                            <input type="text" name="wpforms[hp]" id="wpforms-5346-field-hp" className="wpforms-field-medium" />
                                        </div>
                                        <div className="wpforms-submit-container">
                                            <button
                                                type="submit"
                                                name="wpforms[submit]"
                                                id="wpforms-submit-5346"
                                                className="wpforms-submit"
                                                data-alt-text={t('newsletter.sending')}
                                                data-submit-text={t('newsletter.subscribe')}
                                                aria-live="assertive"
                                                value="wpforms-submit"
                                            >
                                                {t('newsletter.subscribe')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="footer-bottom-widgets">
                    <div className="container">
                        <div className="footer-bottom-widgets-inner row">
                            <div className="footer-contact col-md-5">
                                <Link to="/" className="d-none site-dark-logo">
                                    <img className="img-footer-logo footer-logo" src={FooterBg} alt="B2B Maroc" />
                                </Link>
            
                                <div className="footer-logo">
                                    <img src={FooterBg} alt="B2B Maroc" width="0" height="0" />
                                </div>
            
                                <div className="footer-address">
                                    <strong className="footer-address-title"></strong>
                                    <address>{t('footer.about_description')}</address>
                                </div>
            
                                <div className="footer-social-icons">
                                    <ul className="social-icons list-unstyled nav align-items-center">
                                        <li>
                                            <a className="fab fa-facebook" target="_blank" href={information?.facebook}></a>
                                        </li>
                                        <li>
                                            <a className="fab fa-whatsapp mobile" target="_blank" href={information?.whatssap}></a>
                                        </li>
                                        <li>
                                            <a className="fab fa-whatsapp desktop" target="_blank" href={information?.whatssap}></a>
                                        </li>
                                        <li>
                                            <a className="fab fa-linkedin" target="_blank" href={information?.linkdin}></a>
                                        </li>
                                        <li>
                                            <a className="fab fa-instagram" target="_blank" href={information?.instagram}></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
            
                            <div className="footer-bottom-widgets-menu col-md">
                                <div className="footer-bottom-widgets-menu-inner row g-0 row-cols-xl-3">
                                    <div className="columns col">
                                        <aside id="nav_menu-1" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.know_us')}</h4>
                                                <div className="menu-footer-menu-1-container">
                                                    <ul id="menu-footer-menu-1" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5164 current_page_item menu-item-6294">
                                                            <Link to="/">{t('header.home')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6296">
                                                            <Link to="/a-propos-de-nous/">{t('header.about_us')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7114">
                                                            <Link to="/aide">{t('header.contact')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-6449">
                                                            <Link rel="privacy-policy" to="/conditions-generales-dutilisation/">
                                                                {t('footer.terms_of_use')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="columns col">
                                        <aside id="nav_menu-2" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.buy_sell_title')}</h4>
                                                <div className="menu-footer-menu-2-container">
                                                    <ul id="menu-footer-menu-2" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6280">
                                                            <Link to="/nos-packs-premium">{t('header.services.title')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7109">
                                                            <Link to="/annonces-offres">{t('header.announcements_offers')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7110">
                                                            <Link to="/avantages-de-vendre-sur-b2b-maroc">{t('header.sell_on_b2b')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="columns col">
                                        <aside id="nav_menu-3" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.services')}</h4>
                                                <div className="menu-footer-menu-3-container">
                                                    <ul id="menu-footer-menu-3" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6284">
                                                            <Link to="/aide">{t('header.help')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                            <Link to="/blogs">{t('header.blog')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7117">
                                                            <Link to="/recrutement/">{t('footer.recruitment')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6283">
                                                            <Link to="/wishlist">{t('header.wishlist')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-bar">
                    <div className="container">
                        <div className="float-start copyright">
                            © <Link to="/">B2B Maroc</Link> {t('footer.copyright')}
                        </div>
                        <div className="float-end payment"></div>
                    </div>
                </div>
            </div>

            <div className="handheld-footer d-lg-none v2">
                <div className="footer-newsletter">
                    <div className="container">
                        <div className="footer-newsletter-inner row">
                            <div className="newsletter-content col-lg-7">
                                <h5 className="newsletter-title"></h5>
                                <span className="newsletter-marketing-text">
                                    {t('newsletter.description')}
                                </span>
                            </div>
                            <div className="newsletter-form col-lg-5 align-self-center">
                                <div className="wpforms-container wpforms-container-full ec-newsletter-form" id="wpforms-5346">
                                    <form onSubmit={hundelClick} id="wpforms-form-5346" className="wpforms-validate wpforms-form">
                                        <noscript className="wpforms-error-noscript">
                                            {t('newsletter.enable_js')}
                                        </noscript>
                                        <div className="wpforms-field-container">
                                            <div id="wpforms-5346-field_1-container" className="wpforms-field wpforms-field-email" data-field-id="1">
                                                <label style={{display: "none"}} className="wpforms-field-label wpforms-label-hide" htmlFor="wpforms-5346-field_1">
                                                    {t('form.email')} <span className="wpforms-required-label">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    id="wpforms-5346-field_1"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="wpforms-field-large wpforms-field-required"
                                                    name="wpforms[fields][1]"
                                                    placeholder={t('newsletter.placeholder')}
                                                    spellCheck="false"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: "none"}} className="wpforms-field wpforms-field-hp">
                                            <label htmlFor="wpforms-5346-field-hp" className="wpforms-field-label">
                                                {t('form.name')}
                                            </label>
                                            <input type="text" name="wpforms[hp]" id="wpforms-5346-field-hp" className="wpforms-field-medium" />
                                        </div>
                                        <div className="wpforms-submit-container">
                                            <button
                                                type="submit"
                                                name="wpforms[submit]"
                                                id="wpforms-submit-5346"
                                                className="wpforms-submit"
                                                data-alt-text={t('newsletter.sending')}
                                                data-submit-text={t('newsletter.subscribe')}
                                                aria-live="assertive"
                                                value="wpforms-submit"
                                            >
                                                {t('newsletter.subscribe')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="footer-bottom-widgets">
                    <div className="container">
                        <div className="footer-bottom-widgets-inner row">
                            <div className="footer-contact col-md-5">
                                <Link to="/" className="d-none site-dark-logo">
                                    <img className="img-footer-logo footer-logo" src={FooterBg} alt="B2B Maroc" />
                                </Link>
                                <div className="footer-logo">
                                    <img src={FooterBg} alt="B2B Maroc" width="0" height="0" />
                                </div>
            
                                <div className="footer-address">
                                    <strong className="footer-address-title"></strong>
                                    <address>{t('footer.about_description')}</address>
                                </div>
                            </div>
            
                            <div className="footer-bottom-widgets-menu mt-5 mt-md-0 col-md">
                                <div className="footer-bottom-widgets-menu-inner row g-0 row-cols-xl-3">
                                    <div style={{width: 'fit-content'}} className="columns">
                                        <aside id="nav_menu-1" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.know_us')}</h4>
                                                <div className="menu-footer-menu-1-container">
                                                    <ul id="menu-footer-menu-1" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5164 current_page_item menu-item-6294">
                                                            <Link to="/">{t('header.home')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6296">
                                                            <Link to="/a-propos-de-nous/">{t('header.about_us')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7114">
                                                            <Link to="/aide">{t('header.contact')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-6449">
                                                            <Link rel="privacy-policy" to="/conditions-generales-dutilisation/">
                                                                {t('footer.terms_of_use')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div style={{width: 'fit-content'}} className="columns">
                                        <aside id="nav_menu-2" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.buy_sell_title')}</h4>
                                                <div className="menu-footer-menu-2-container">
                                                    <ul id="menu-footer-menu-2" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6280">
                                                            <Link to="/nos-packs-premium">{t('header.services.title')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7109">
                                                            <Link to="/annonces-offres">{t('header.announcements_offers')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7110">
                                                            <Link to="/avantages-de-vendre-sur-b2b-maroc">{t('header.sell_on_b2b')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div style={{width: 'fit-content'}} className="columns">
                                        <aside id="nav_menu-3" className="widget clearfix widget_nav_menu">
                                            <div className="body">
                                                <h4 className="widget-title">{t('footer.services')}</h4>
                                                <div className="menu-footer-menu-3-container">
                                                    <ul id="menu-footer-menu-3" className="menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6284">
                                                            <Link to="/aide">{t('header.help')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                            <Link to="/blog">{t('header.blog')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7117">
                                                            <Link to="/recrutement/">{t('footer.recruitment')}</Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6283">
                                                            <Link to="/wishlist">{t('header.wishlist')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
            
                            <div className="footer-social-icons mt-3">
                                <ul style={{fontSize: '20px'}} className="social-icons list-unstyled nav justify-content-md-start justify-content-center align-items-center">
                                    <li>
                                        <a className="fab fa-facebook" target="_blank" href={information?.facebook}></a>
                                    </li>
                                    <li>
                                        <a className="fab mt-0 fa-whatsapp mobile" target="_blank" href={information?.whatssap}></a>
                                    </li>
                                    <li>
                                        <a className="fab fa-whatsapp desktop" target="_blank" href={information?.whatssap}></a>
                                    </li>
                                    <li>
                                        <a className="fab fa-linkedin" target="_blank" href={information?.linkdin}></a>
                                    </li>
                                    <li>
                                        <a className="fab fa-instagram" target="_blank" href={information?.instagram}></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="copyright-bar">
                    <div className="container">
                        <div className="float-start copyright">
                            © <Link to="/">B2B Maroc</Link> {t('footer.copyright')}
                        </div>
                        <div className="float-end payment"></div>
                    </div>
                </div>
            </div>
        
        </footer>
    );
};

export default Footer;
