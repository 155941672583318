import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosClient } from '../../constants/axionsClient';
import {Annonce} from '../'

const RelatedProducts = ({AnnounceRef, category_name}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([])

  useEffect(()=>{

    (async()=>{

      try {
        
        const res = await axiosClient.get(`/related-announces/${AnnounceRef}/${category_name}`)

        if(res.status === 200){

          setProducts(res.data)

        }

      } catch (error) {
        console.log(error);
      }

    })()

  }, [])

  return (
    <section className="related products">
      <h2>{t('related_products.title')}</h2>
      <ul className="products products list-unstyled row g-0 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5">
        
        {
          products?.map((item)=>(
            <Annonce info={item} isSim={true}/>
          ))
        }

      </ul>
    </section>
  );
};

export default RelatedProducts;
