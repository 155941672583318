export const categories = [
    {
        name: "Agroalimentaire",
        icon: "fas fa-seedling",
        subcategories: [
            {
                name: "Agriculture et sylviculture et Matériaux liés",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/agriculture-et-sylviculture-et-materiaux-lies/"
            },
            {
                name: "Alimentation & Boissons et Matériel pour l'industrie agroalimentaire et restauration",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/alimentation-boissons-et-materiel-pour-lindustrie-agroalimentaire-et-restauration/"
            },
            {
                name: "Elevage et pêche",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/elevage-et-peche/"
            },
            {
                name: "Produits Bio",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/produits-bio/"
            }
        ]
    },
    {
        name: "Matériel électrique, électronique, optique",
        icon: "fas fa-microchip",
        subcategories: [
            {
                name: "Electronics",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/agriculture-et-sylviculture-et-materiaux-lies/"
            },
            {
                name: "Matériel électrique. Matériel nucléaire",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/alimentation-boissons-et-materiel-pour-lindustrie-agroalimentaire-et-restauration/"
            },
            {
                name: "Matériel d'optique, de photographie et de cinématographie",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/elevage-et-peche/"
            },
            {
                name: "Matériel de mesure et d'essais",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/produits-bio/"
            },
            {
                name: "Matériel électronique. Matériel de télécommunications",
                url: "https://www.b2bmaroc.com/product-category/agroalimentaire/produits-bio/"
            },
        ]
    },
    {
        name: "Chimie, Plastique",
        icon: "fa fa-flask",
        subcategories: [
            {
                name: "Matériel de traitement du caoutchouc et du plastique",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/materiel-de-traitement-du-caoutchouc-et-du-plastique/"
            },
            {
                name: "Matériel pour l'industrie chimique",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/materiel-pour-lindustrie-chimique/"
            },
            {
                name: "Parachimie",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/parachimie/"
            },
            {
                name: "Produits chimiques de base",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/produits-chimiques-de-base/"
            },
            {
                name: "Produits en caoutchouc",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/produits-en-caoutchouc/"
            },
            {
                name: "Produits en plastique",
                url: "https://www.b2bmaroc.com/product-category/chimie-plastique/produits-en-plastique/"
            }
        ]
    },
    {
        name: "Construction, Bâtiment, Bois, Habitat",
        icon: "fa fa-building",
        subcategories: [
            {
                name: "Bâtiment",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/batiment/"
            },
            {
                name: "Bois",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/bois/"
            },
            {
                name: "Chauffage, réfrigération et ventilation",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/chauffage-refrigeration-et-ventilation/"
            },
            {
                name: "Eléments métalliques pour le bâtiment",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/elements-metalliques-pour-le-batiment/"
            },
            {
                name: "Matériel pour les travaux publics et le bâtiment",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/materiel-pour-les-travaux-publics-et-le-batiment/"
            },
            {
                name: "Meubles, linge de maison et literie",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/meubles-linge-de-maison-et-literie/"
            },
            {
                name: "Quincaillerie, coutellerie et outillage",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/quincaillerie-coutellerie-et-outillage/"
            },
            {
                name: "Serrurerie, alarmes et sécurité",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/serr urerie-alarmes-et-securite/"
            },
            {
                name: "Travaux publics et maritimes",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/travaux-publics-et-maritimes/"
            },
            {
                name: "Tuyauterie, robinetterie et contenants en métal",
                url: "https://www.b2bmaroc.com/product-category/construction-batiment-bois-habitat/tuyauterie-robinetterie-et-contenants-en-metal/"
            }
        ]
    },
    {
        name: "Énergie, Environnement",
        icon: "fa fa-sun",
        subcategories: [
            {
                name: "Énergie renouvelable",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/energie-renouvelable/"
            },
            {
                name: "Équipements pour l'environnement",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/equipements-pour-lenvironnement/"
            },
            {
                name: "Matériel pour la production d'énergie",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-production-denergie/"
            },
            {
                name: "Matériel pour la réduction des déchets",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-reduction-des-dechets/"
            },
            {
                name: "Matériel pour la réduction des émissions",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-reduction-des-emissions/"
            },
            {
                name: "Matériel pour la réduction des pollutions",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-reduction-des-pollutions/"
            },
            {
                name: "Matériel pour la réduction des résidus",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-reduction-des-residus/"
            },
            {
                name: "Matériel pour la réduction des déchets dangereux",
                url: "https://www.b2bmaroc.com/product-category/energie-environnement/materiel-pour-la-reduction-des-dechets-dangereux/"
            }
        ]
    },
    {
        name: "Industrie, Mécanique, Équipements",
        icon: "fa fa-cogs",
        subcategories: [
            {
                name: "Équipements de production",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-de-production/"
            },
            {
                name: "Équipements pour l'industrie agroalimentaire",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-pour-lindustrie-agroalimentaire/"
            },
            {
                name: "Équipements pour l'industrie chimique",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-pour-lindustrie-chimique/"
            },
            {
                name: "Équipements pour l'industrie du bois",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-pour-lindustrie-du-bois/"
            },
            {
                name: "Équipements pour l'industrie du textile",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-pour-lindustrie-du-textile/"
            },
            {
                name: "Équipements pour l'industrie métallurgique",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/equipements-pour-lindustrie-metallurgique/"
            },
            {
                name: "Matériel de levage et de manutention",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/materiel-de-levage-et-de-manutention/"
            },
            {
                name: " Matériel pour l'industrie des métaux",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/materiel-pour-lindustrie-des-metaux/"
            },
            {
                name: "Matériel pour l'industrie des plastiques",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/materiel-pour-lindustrie-des-plastiques/"
            },
            {
                name: "Matériel pour l'industrie des textiles",
                url: "https://www.b2bmaroc.com/product-category/industrie-mecanique-equipements/materiel-pour-lindustrie-des-textiles/"
            }
        ]
    },
    {
        name: "Informatique, Télécommunications",
        icon: "fa fa-computer",
        subcategories: [
            {
                name: "Équipements pour les télécommunications",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/equipements-pour-les-telecommunications/"
            },
            {
                name: "Logiciels",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/logiciels/"
            },
            {
                name: "Matériel informatique",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/materiel-informatique/"
            },
            {
                name: "Matériel pour les réseaux",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/materiel-pour-les-reseaux/"
            },
            {
                name: "Matériel pour les systèmes d'information",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/materiel-pour-les-systemes-dinformation/"
            },
            {
                name: "Services pour les télécommunications",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/services-pour-les-telecommunications/"
            },
            {
                name: "Services pour l'informatique",
                url: "https://www.b2bmaroc.com/product-category/informatique-telecommunications/services-pour-linformatique/"
            }
        ]
    },
    {
        name: "Métallurgie, Mécanique",
        icon: "fa fa-wrench",
        subcategories: [
            {
                name: "Équipements pour la métallurgie",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/equipements-pour-la-metallurgie/"
            },
            {
                name: "Matériel pour la fabrication des métaux",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/materiel-pour-la-fabrication-des-metaux/"
            },
            {
                name: "Matériel pour la métallurgie",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/materiel-pour-la-metallurgie/"
            },
            {
                name: "Matériel pour la mécanique",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/materiel-pour-la-mecanique/"
            },
            {
                name: "Matériel pour la réparation des métaux",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/materiel-pour-la-reparation-des-metaux/"
            },
            {
                name: "Matériel pour la transformation des métaux",
                url: "https://www.b2bmaroc.com/product-category/metallurgie-mecanique/materiel-pour-la-transformation-des-metaux/"
            }
        ]
    },
    {
        name: "Papier, Carton, Imprimerie",
        icon: "fa fa-newspaper",
        subcategories: [
            {
                name: "Équipements pour l'imprimerie",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/equipements-pour-limprimerie/"
            },
            {
                name: "Matériel pour la fabrication du papier",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/materiel-pour-la-fabrication-du-papier/"
            },
            {
                name: " Matériel pour la fabrication du carton",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/materiel-pour-la-fabrication-du-carton/"
            },
            {
                name: "Matériel pour l'imprimerie",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/materiel-pour-limprimerie/"
            },
            {
                name: "Matériel pour la transformation du papier",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/materiel-pour-la-transformation-du-papier/"
            },
            {
                name: "Matériel pour la transformation du carton",
                url: "https://www.b2bmaroc.com/product-category/papier-carton-imprimerie/materiel-pour-la-transformation-du-carton/"
            }
        ]
    },
    {
        name: "Santé, Médical, Pharmacie",
        icon: "fa fa-medkit",
        subcategories: [
            {
                name: "Équipements médicaux",
                url: "https://www.b2bmaroc.com/product-category/sante-medical-pharmacie/equipements-medicaux/"
            },
            {
                name: "Matériel pour les laboratoires",
                url: "https://www.b2bmaroc.com/product-category/sante-medical-pharmacie/materiel-pour-les-laboratoires/"
            },
            {
                name: "Matériel pour les pharmacies",
                url: "https://www.b2bmaroc.com/product-category/sante-medical-pharmacie/materiel-pour-les-pharmacies/"
            },
            {
                name: "Matériel pour les soins de santé",
                url: "https://www.b2bmaroc.com/product-category/sante-medical-pharmacie/materiel-pour-les-soins-de-sante/"
            },
            {
                name: "Produits pharmaceutiques",
                url: "https://www.b2bmaroc.com/product-category/sante-medical-pharmacie/produits-pharmaceutiques/"
            }
        ]
    },
    {
        name: "Textile, Habillement, Cuir",
        icon: "fa fa-tshirt",
        subcategories: [
            {
                name: "Équipements pour l'industrie textile",
                url: "https://www.b2bmaroc.com/product-category/textile-habillement-cuir/equipements-pour-lindustrie-textile/"
            },
            {
                name: "Matériel pour la confection",
                url: "https://www.b2bmaroc.com/product-category/textile-habillement-cuir/materiel-pour-la-confection/"
            },
            {
                name: "Matériel pour la fabrication des tissus",
                url: "https://www.b2bmaroc.com/product-category/textile-habillement-cuir/materiel-pour-la-fabrication-des-tissus/"
            },
            {
                name: "Matériel pour la fabrication des vêtements",
                url: "https://www.b2bmaroc.com/product-category/textile-habillement-cuir/materiel-pour-la-fabrication-des-vetements/"
            },
            {
                name: "Matériel pour la transformation des cuirs",
                url: "https://www.b2bmaroc.com/product-category/textile-habillement-cuir/materiel-pour-la-transformation-des-cuirs/"
            }
        ]
    },
    {
        name: "Transports, Logistique",
        icon: "fa fa-truck",
        subcategories: [
            {
                name: "Équipements pour les transports",
                url: "https://www.b2bmaroc.com/product-category/transports-logistique/equipements-pour-les-transports/"
            },
            {
                name: "Matériel pour la logistique",
                url: "https://www.b2bmaroc.com/product-category/transports-logistique/materiel-pour-la-logistique/"
            },
            {
                name: "Matériel pour les transports routiers",
                url: "https://www.b2bmaroc.com/product-category/transports-logistique/materiel-pour-les-transports-routiers/"
            },
            {
                name: "Matériel pour les transports maritimes",
                url: "https://www.b2bmaroc.com/product-category/transports-logistique/materiel-pour-les-transports-maritimes/"
            },
            {
                name: "Matériel pour les transports aériens",
                url: "https://www.b2bmaroc.com/product -category/transports-logistique/materiel-pour-les-transports-aeriens/"
            }
        ]
    }
];