import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnnouncesOption, FilterSide, NavLink } from '../../components';
import { axiosClient } from '../../constants/axionsClient';
import { useTranslation } from "react-i18next";

const AnnoncesOffresPage = ({
    inBody = false
}) => {

    const { t } = useTranslation();

    const CompRef = useRef()
    const { query } = useParams()
    const {category, subcategory} = useParams()

    const [annonces, setAnnonces] = useState([])
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [ville, setVille] = useState('')
    const [SelectedCountry, setSelectedCountry] = useState('')

    const [isFiltred, setIsFiltred] = useState(false)
    const [isCityFiltred, setIsCityFiltred] = useState(false)
    const [ItemsPerPage, setItemsPerPage] = useState(2)
    
    const [totalPages, setTotalPages] = useState(0)
    const [TotalResults, setTotalResults] = useState(0)

    const [IsLoading, setIsLoading] = useState(true)
    const [Scrolling, setScrolling] = useState(false)

    useEffect(() => {
        
        if (CompRef.current && Scrolling) {
            CompRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrolling(false)
        }

    }, [Scrolling])

    const handleSubmit = (event, type) => {

        event.preventDefault();

        if(type === 'price'){


            if(parseInt(maxPrice) < parseInt(minPrice)){
    
                setMinPrice(parseInt(maxPrice))
                setMaxPrice(parseInt(minPrice))
    
            }
    
            setIsFiltred(true)
            getAnnonces(
                parseInt(maxPrice) < parseInt(minPrice) ? minPrice : maxPrice, 
                parseInt(minPrice) > parseInt(maxPrice) ? maxPrice : minPrice, 
                isCityFiltred ? ville : '',
                'default' , 
                1
            )

        }

        if(type === 'ville'){

            getAnnonces(
                isFiltred ? minPrice : '', 
                isFiltred ? maxPrice : '', 
                ville,
                'default' , 
                1,
                SelectedCountry
            )

            setIsCityFiltred(true)

        }

    };

    const hundelReset = (event, type) => {

        event.preventDefault();

        if(type === 'ville'){

            setVille('')
            setIsCityFiltred(false)

            getAnnonces(
                isFiltred ? maxPrice : '', 
                isFiltred ? minPrice : '', 
                '',
                'default' , 1
            )
            

        }

        if(type === 'price'){
            setMaxPrice(0)
            setMinPrice(0)
            setIsFiltred(false)

            getAnnonces(
                '', 
                '', 
                isCityFiltred ? ville : '',
                'default' , 1
            )

        }

    };

    const getAnnonces = async (
        max_price = '', min_price = '', ville = '' , sortWith = 'default', CurrentPage, country = ''
    ) => {

        setIsLoading(true)

        try {

            const res = await axiosClient.get(`/get-announcements/${CurrentPage}?search=${query || ''}&sortWith=${sortWith}&min_price=${min_price}&max_price=${max_price}&city=${ville}&category=${category || ''}&subcategory=${subcategory || ''}&country=${country}`)

            if(res.status === 200){

                setAnnonces(res?.data?.announcements)
                setTotalPages(res?.data?.total_pages)
                setTotalResults(res?.data?.totalResults)

            }

        } catch (error) {
            console.log(error);
        }

        setIsLoading(false)

    }

  return (
    <>

        {
            !inBody && (
                <div>
                  <NavLink step_1={t('annonces.navigation.home')} step_2={ category || t('annonces.navigation.announcements_offers')} step_3={query || subcategory} />
                </div>
            )
        }
      <div className="site-content-inner row">

          <div id="primary" className="content-area">

              <AnnouncesOption
                  getAnnonces={getAnnonces}
                    header={ 
                        query ? 
                        t('annonces.results.title', { query: query }) 
                        : 
                        subcategory || category || t('annonces.navigation.announcements_offers')
                    }
                  annonces={annonces}
                  TotalResults={TotalResults}
                  totalPages={totalPages}
                  isFiltred={isFiltred}
                  isCityFiltred={isCityFiltred}
                  maxPrice={maxPrice}
                  minPrice={minPrice}
                  ville={ville}
                  ItemsPerPage={ItemsPerPage}
                  IsLoading={IsLoading}
                  CompRef={CompRef}
                  setScrolling={setScrolling}
                  RecommondedProducts={false}
                  query={query}
              />

          </div>

          <FilterSide
              maxPrice={maxPrice}
              minPrice={minPrice}
              setSelectedCountry={setSelectedCountry}
              setMinPrice={setMinPrice}    
              setMaxPrice={setMaxPrice}    
              handleSubmit={handleSubmit}
              hundelReset={hundelReset}
              ville={ville}
              setVille={setVille}
          />

      </div>
    </>
  )
}

export default AnnoncesOffresPage