import React, { useState } from 'react'
import { isLogged, redirectTo } from '../constants/AuthFunctions';
import bgImage from "../assests/Slide-2-Vendez-vos-produits-ou-services-trouvez-des-acheteurs-et-clients-cibles-2.jpg";
import { Link, useNavigate } from 'react-router-dom';
import { dashboardRoute } from '../constants/env';
import { useTranslation } from 'react-i18next';

function Hero() {
  const navigate = useNavigate()
  const [auth, setAuth] = useState(isLogged());
  const { t } = useTranslation();

  // Add your CSS styles here to make the hero responsive and adjustable
  const heroStyles = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
    overflow: 'hidden',
    zIndex: '-1',
    marginTop: '-10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    textAlign: 'center'
  }
  
  return (
    <>
      <div className="page-content">
        <div className="entry-content"></div>
      </div>
      <div
        className="stretch-full-width slider-with-das mb-5"
        style={heroStyles}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg hero">
              <h4>{t('hero.title.part1')} <br/> {t('hero.title.part2')}</h4>
              <h1>{t('hero.title.subtitle')}</h1>
              <div
                className="call-to-action bg-warning"
                style={{
                  cursor: 'pointer'
                }}
                onClick={
                  auth ? 
                    (e) => redirectTo(e)
                  :
                    () => navigate('/auth/login')
                }
              >
                <i className="fa-solid fa-building px-3"></i>
                {auth ? t('hero.cta.manage') : t('hero.cta.create')}
              </div>
            </div>

            <div className="col-lg-auto">
              <div className="da-block pt-xl-5 mt-2 pb-xl-4">
                <div className="da">
                  <div className="da-inner px-3 py-3 position-relative">
                    <Link
                      className="da-media d-flex stretched-link"
                      to={
                        !auth ? "/auth/login" : `/fournisseurs/${JSON.parse(localStorage.getItem("user"))?.company_id}`
                      }
                    >
                      <div className="da-media-body">
                        <div
                          className="da-text mb-3 text-capitalize"
                          style={{ fontSize: "16px", lineHeight: "26px", textAlign: "start" }}
                        >
                          {t('hero.sell_products.text1')}{" "}
                          <strong>
                            {t('hero.sell_products.text2')} <br /> {t('hero.sell_products.text3')}
                          </strong>{" "}
                          {t('hero.sell_products.text4')} <br /> {t('hero.sell_products.text5')}
                        </div>
                        <div className="da-action">
                          {auth ? t('hero.sell_products.view_page') : t('hero.sell_products.create_page')}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="da">
                  <div className="da-inner px-3 py-3 position-relative">
                    <a
                      className="da-media d-flex stretched-link"
                      target="_blank"
                      href={
                        auth ? "#" : `${dashboardRoute}/mes-announces`
                      }
                      onClick={
                        auth ? (e) => redirectTo(e) : null
                      }
                    >
                      <div className="da-media-body">
                        <div
                          className="da-text mb-3 text-capitalize"
                          style={{ fontSize: "16px", lineHeight: "26px", textAlign: "start" }}
                        >
                          {t('hero.buyer_seller.text1')} <strong>{t('hero.buyer_seller.text2')}</strong>,{" "}
                          <strong>{t('hero.buyer_seller.text3')}</strong> {t('hero.buyer_seller.text4')}
                          <br /> <strong>{t('hero.buyer_seller.text5')}</strong>. {t('hero.buyer_seller.text6')}
                          <br /> {t('hero.buyer_seller.text7')}
                        </div>
                        <div className="da-action">{t('hero.buyer_seller.action')}</div>
                      </div>
                    </a>
                  </div>
                </div>
                
                <div className="da mt-2">
                  <div
                    className="da-inner px-3 py-3 position-relative"
                    style={{ background: "#ee9e06" }}
                  >
                    <Link
                      className="da-media d-flex stretched-link"
                      to="/nos-packs-premium"
                    >
                      <div className="da-media-body">
                        <div
                          className="da-text mb-2 text-capitalize"
                          style={{ fontSize: "16px", lineHeight: "26px", textAlign: "start" }}
                        >
                          {t('hero.premium.text1')}
                          <br /> {t('hero.premium.text2')} <strong>{t('hero.premium.text3')}</strong>
                          <br /> {t('hero.premium.text4')} <br /> {t('hero.premium.text5')}
                          <br /> {t('hero.premium.text6')}
                        </div>
                        <div className="da-action">{t('hero.premium.action')}</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero