import toast from "react-hot-toast";
import { axiosClient } from "./axionsClient";
import { baseRoute, dashboardRoute } from "./env";

export const isLogged = () => {
    return !!localStorage.getItem('token');
}

export const redirectTo = async (e) => {
  e.preventDefault();

  try {

    const res = await axiosClient.post("/redirect-to", {
      url: `${dashboardRoute}/login-with-session`,
    });

    if (res.status === 200) {
      window.location.href = res.data.url;
    }

  } catch (error) {

    console.log(error);
    
  }
};

export const logout = async (e) => {
  e.preventDefault();
  toast.loading("loading...");
  try {
    const res = await axiosClient.post("/logout");

    if (res.status == 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      toast.dismiss();
      toast.success(res.data.message);
      window.location.reload()
    }
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.success("Déconnexion échouée");
  }
};
