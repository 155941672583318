import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { blogs_bg } from "../../constants/images";
import articles from './TranslateArData.js';

const Blog = () => {

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "js-comp-ver-6.8.0",
      "vc_responsive",
      "customize-support",
      "woocommerce-js",
      "translatepress-fr_FR",
      "left-sidebar",
      "wpb-js-composer",
      "logged-in",
      "admin-bar",
      "theme-electro",
      "woocommerce",
      "woocommerce-page",
      "normal",
      "tax-product_cat",
      "term-materiel-electrique-electronique-optique",
      "term-15",
      "archive"
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "js-comp-ver-6.8.0",
        "vc_responsive",
        "customize-support",
        "woocommerce-js",
        "translatepress-fr_FR",
        "left-sidebar",
        "wpb-js-composer",
        "logged-in",
        "admin-bar",
        "theme-electro",
        "woocommerce",
        "woocommerce-page",
        "normal",
        "tax-product_cat",
        "term-materiel-electrique-electronique-optique",
        "term-15",
        "archive"
      );
    };
  }, []);

  const {t, i18n} = useTranslation()
  const navigate = useNavigate();
  
  return (
    <div id="primary" style={{width : '100%'}} className="content-area">
      <main id="main" className="site-main">
        <article
          id="post-2505"
          className="post-2505 page type-page status-publish has-post-thumbnail hentry"
        >
          <header
            className="entry-header header-with-cover-image"
            style={{
              backgroundImage: `url(${blogs_bg})`,
            }}
          >
            <div className="caption">
              <h1 className="entry-title"></h1>
            </div>
          </header>
        <div className="blog-container">
          {/* <img src={headerImage} alt="Header" className="blog-header-image" /> */}
          <div className="blog-title-container">
            <h1 className="blog-title">{t('blogs.title')}</h1>
            <div className="blog-title-underline"></div>
          </div>
          <div className="blog-content">
            <div className="blog-grid">
              {articles.map((article) => {
                const content = article.translation[i18n.language]
                return <div
                  key={article.id}
                  onClick={() => navigate(`/blog/${article.id}`)}
                  className="blog-card"
                >
                  <img
                    src={article.imageUrl}
                    alt={content.title}
                    className="blog-card-image"
                  />
                  <div>
                    <p className="blog-card-category">{content.category}</p>
                    <h3 className="blog-card-title">{content.title}</h3>
                    <p className="blog-card-description">{content.description}</p>
                  </div>
                </div>
                })}
            </div>
          </div>
        </div>
        </article>
      </main>
    </div>
  );
};

export default Blog;
