import React from 'react'

const Loading = ({style, loadingStyle}) => {
    
  return (
    <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
    }}>
        <div style={loadingStyle} className="loader"></div>
    </div>
  )
}

export default Loading