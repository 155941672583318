import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FournisseurContent, NavLink, SearchFBar, SideFournisseurs } from '../../components'
import { axiosClient } from '../../constants/axionsClient'

const Fournisseurs = () => {
  const { t } = useTranslation()
  const CompRef = useRef()
  const { query } = useParams()
  const [city, setCity] = useState('')
  const [CountrySelected, setCountrySelected] = useState('')
  const [TypeSelected, setTypeSelected] = useState('')
  const [Companies, setCompanies] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [TotalResults, setTotalResults] = useState(0)
  const [IsLoading, setIsLoading] = useState(false)
  const [Scrolling, setScrolling] = useState(false)
  const [ItemsPerPage, setItemsPerPage] = useState(24)
  const [sortWith, setSortWith] = useState('sells');

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "js-comp-ver-6.8.0",
      "vc_responsive",
      "customize-support",
      "woocommerce-js",
      "translatepress-fr_FR",
      "left-sidebar",
      "wpb-js-composer",
      "logged-in",
      "admin-bar",
      "theme-electro",
      "woocommerce",
      "woocommerce-page",
      "normal",
      "tax-product_cat",
      "term-materiel-electrique-electronique-optique",
      "term-15",
      "archive"
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "js-comp-ver-6.8.0",
        "vc_responsive",
        "customize-support",
        "woocommerce-js",
        "translatepress-fr_FR",
        "left-sidebar",
        "wpb-js-composer",
        "logged-in",
        "admin-bar",
        "theme-electro",
        "woocommerce",
        "woocommerce-page",
        "normal",
        "tax-product_cat",
        "term-materiel-electrique-electronique-optique",
        "term-15",
        "archive"
      );
    };
  }, []);

  useEffect(() => {
        
    if (CompRef.current && Scrolling) {
        CompRef.current.scrollIntoView({ behavior: 'smooth' });
        setScrolling(false)
    }

  }, [Scrolling])

  const getCompanies = async (CurrentPage, sortWith = 'sells'
  ) => {
    
      setIsLoading(true)

      try {

          const res = await axiosClient.get(`/companies/get-companies/${CurrentPage}?SearchQuery=${query || ''}&FiltredBy=${sortWith}&type=${TypeSelected}&city=${city}&country=${CountrySelected}`)

          if(res.status === 200){

              setCompanies(res?.data?.data)
              setTotalPages(res?.data?.total_pages)
              setTotalResults(res?.data?.totalResults)

          }

      } catch (error) {
          console.log(t('fournisseurs.error_message'));
      }

      setIsLoading(false)

  }

  return (
    <>

      <div>
        <NavLink 
          step_1={t('fournisseurs.nav_links.home')} 
          step_2={t('fournisseurs.nav_links.find_suppliers')} 
        />
      </div>

      <div className="site-content-inner row">

          <div id="primary" className="content-area">
            <main ref={CompRef} id="main" className="site-main">
              
              <SearchFBar 
                sortWith={sortWith} 
                setCountrySelected={setCountrySelected}
                hundelClick={getCompanies} 
                setCity={setCity} 
                setTypeSelected={setTypeSelected} 
              />

              <FournisseurContent 
                getCompanies={getCompanies}
                query={query}
                header={query ? t('fournisseurs.results.query_header', { query }) : t('fournisseurs.results.header')}
                TypeSelected={TypeSelected}
                city={city}
                Companies={Companies}
                TotalResults={TotalResults}
                totalPages={totalPages}
                IsLoading={IsLoading}
                setScrolling={setScrolling}
                ItemsPerPage={ItemsPerPage}
                setSortWith={setSortWith}
                sortWith={sortWith}
              />

            </main>
          </div>

          <SideFournisseurs />

      </div>
    </>
  )
}

export default Fournisseurs