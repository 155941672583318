import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosClient } from "../../constants/axionsClient";
import { aide_bg } from "../../constants/images";
import { useTranslation } from "react-i18next";

function ContactForm({ t }) {
  const [formData, setFormData] = useState({
    first_name: '',
    second_name: '',
    company_name: '',
    email: '',
    phone: '',
    content: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true)
    toast.loading(t('aide_page.contact_form.submit_loading'))
    try {

      const res = await axiosClient.post('/contact-us', formData);

      if (res.status === 200) {

        toast.dismiss()
        toast.success(t('aide_page.contact_form.submit_success'))
        setFormData({
          first_name: '',
          second_name: '',
          company_name: '',
          email: '',
          phone: '',
          content: '',
        })

      }

    } catch (error) {

      toast.dismiss()
      toast.error(t('aide_page.contact_form.submit_error'))

      if (error.response.status === 422) {

        setErrors(error.response.data)

      }

    }
    setIsSubmitting(false)

  };

  return (
    <section
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      className="vc_section contact-section-contain vc_custom_1649892477130 vc_section-has-fill" >
      <div className="vc_row contact-section wpb_row vc_row-fluid inner-bottom-xs">

        <div className=" contact-section-1 wpb_column vc_column_container vc_col-sm-6">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <h2 style={{ fontSize: '25px', color: '#434343', textAlign: 'left', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }} className="vc_custom_heading contact-page-title vc_custom_1649892928514">
                {t('aide_page.contact_form.title')}
              </h2>
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  {t('aide_page.contact_form.description_paragraphs', { joinArrays: '\n' }).split('\n').map((paragraph, index) => (
                    <p key={index} style={{ textAlign: 'left' }}>{paragraph}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" contact-section-2 wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
          <div className="vc_column-inner vc_custom_1649892566099">
            <div className="wpb_wrapper">
              <div className="wpforms-container wpforms-container-full" id="wpforms-5345">
                <form id="wpforms-form-5345" className="wpforms-validate wpforms-form" onSubmit={handleSubmit}>
                  <noscript className="wpforms-error-noscript">
                    {t('aide_page.contact_form.noscript_error')}
                  </noscript>
                  <div className="wpforms-field-container">
                    <div id="wpforms-5345-field_0-container" className="wpforms-field wpforms-field-name" data-field-id="0">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_0">
                        {t('aide_page.contact_form.labels.name_surname')} <span className="wpforms-required-label">{t('aide_page.contact_form.required')}</span>
                      </label>
                      <div className="wpforms-field-row wpforms-field-large">
                        <div className="wpforms-field-row-block wpforms-first wpforms-one-half">
                          <input
                            type="text"
                            id="wpforms-5345-field_0"
                            className="wpforms-field-name-first wpforms-field-required"
                            name="first_name"
                            style={{
                              marginBottom: !errors.first_name ? '10px' : '0'
                            }}
                            value={formData.first_name}
                            placeholder={t('aide_page.contact_form.placeholders.first_name')}
                            onChange={handleChange}
                            required
                          />
                          {errors.first_name && <p className="error-text">{errors.first_name}</p>}
                        </div>
                        <div className="wpforms-field-row-block wpforms-one-half">
                          <input
                            type="text"
                            id="wpforms-5345-field_0-last"
                            className="wpforms-field-name-last wpforms-field-required"
                            name="second_name"
                            value={formData.second_name}
                            placeholder={t('aide_page.contact_form.placeholders.last_name')}
                            onChange={handleChange}
                            required
                          />
                          {errors.second_name && <p className="error-text">{errors.second_name}</p>}
                        </div>
                      </div>
                    </div>

                    <div id="wpforms-5345-field_4-container" className="wpforms-field wpforms-field-email" data-field-id="4">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_4">
                        {t('aide_page.contact_form.labels.company')} <span className="wpforms-required-label">{t('aide_page.contact_form.required')}</span>
                      </label>
                      <input
                        type="text"
                        id="wpforms-5345-field_4"
                        className="wpforms-field-large wpforms-field-required"
                        name="company_name"
                        value={formData.company_name}
                        placeholder={t('aide_page.contact_form.placeholders.company_name')}
                        onChange={handleChange}
                        required
                      />
                      {errors.company_name && <p className="error-text">{errors.company_name}</p>}
                    </div>

                    <div id="wpforms-5345-field_1-container" className="wpforms-field wpforms-field-email" data-field-id="1">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_1">
                        {t('aide_page.contact_form.labels.email')} <span className="wpforms-required-label">{t('aide_page.contact_form.required')}</span>
                      </label>
                      <input
                        type="email"
                        id="wpforms-5345-field_1"
                        className="wpforms-field-required wpforms-field-email-primary"
                        name="email"
                        value={formData.email}
                        placeholder={t('aide_page.contact_form.placeholders.email')}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <p className="error-text">{errors.email}</p>}
                    </div>

                    <div id="wpforms-5345-field_6-container" className="wpforms-field wpforms-field-email" data-field-id="6">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_6">
                        {t('aide_page.contact_form.labels.phone')}
                      </label>
                      <input
                        type="text"
                        id="wpforms-5345-field_6"
                        className="wpforms-field-large"
                        name="phone"
                        value={formData.phone}
                        placeholder={t('aide_page.contact_form.placeholders.phone')}
                        onChange={handleChange}
                      />
                      {errors.phone && <p className="error-text">{errors.phone}</p>}
                    </div>

                    <div id="wpforms-5345-field_2-container" className="wpforms-field wpforms-field-textarea" data-field-id="2">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_2">
                        {t('aide_page.contact_form.labels.message')} <span className="wpforms-required-label">{t('aide_page.contact_form.required')}</span>
                      </label>
                      <textarea
                        id="wpforms-5345-field_2"
                        className="wpforms-field-large wpforms-field-required"
                        name="content"
                        style={{
                          height: '150px'
                        }}
                        value={formData.content}
                        placeholder={t('aide_page.contact_form.placeholders.message')}
                        onChange={handleChange}
                        required
                      />
                      {errors.content && <p className="error-text">{errors.content}</p>}
                    </div>
                  </div>

                  <div className="wpforms-submit-container">
                    <button
                      type="submit"
                      id="wpforms-submit-5345"
                      className="wpforms-submit"
                      name="wpforms[submit]"
                      value="wpforms-submit"
                      disabled={isSubmitting}
                    >
                      {t('aide_page.contact_form.labels.message')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const AidePage = () => {

  const { t } = useTranslation()
  const openQuation = (e) => {

    // Check if the clicked element is an h4 inside a vc_toggle
    if (e.target.tagName === 'H4') {

      // Add 'test-2' class to the parent vc_toggle element
      e.target.closest('.vc_toggle').classList.toggle('vc_toggle_active');

      // Get the second child of the parent vc_toggle element
      const secondChild = e.target.closest('.vc_toggle').children[1]; // Access second child

      // If the second child exists, set its display to block
      if (secondChild) {

        if (secondChild.style.display == 'none') {
          secondChild.style.display = 'block';
        } else {
          secondChild.style.display = 'none';
        }

      }

    }

  }
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  useEffect(() => {

    const vcToggles = document.querySelectorAll('.vc_toggle');

    // Add event listeners to each of them
    vcToggles.forEach((toggle) => {

      toggle.addEventListener('click', openQuation);

    });

    // Cleanup event listeners on component unmount
    return () => {
      vcToggles.forEach((toggle) => {
        toggle.removeEventListener('click', openQuation);
      });
    };
  }, []);

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "home",
      "page-template-default",
      "page-id-79",
      "page",
      "wp-custom-logo",
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "home",
        "page",
        "page-template-default",
        "page",
        "page-id-79",
        "wp-custom-logo",
      );
    };
  }, []);

  return (
    <div id="primary" className="content-area">
      <main id="main" className="site-main">
        <article
          id="post-2505"
          className="post-2505 page type-page status-publish has-post-thumbnail hentry"
        >
          <header
            className="entry-header header-with-cover-image"
            style={{
              backgroundImage: `url(${aide_bg})`,
            }}
          >
            <div className="caption">
              <h1 className="entry-title"></h1>
            </div>
          </header>

          <div className="entry-content">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1649892704813">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                  <div style={{ paddingTop: '50px' }} className="wpb_wrapper">
                    <h1
                      style={{
                        fontSize: "25px",
                        color: "#434343",
                        textAlign: "left",
                      }}
                      className="vc_custom_heading contact-page-title"
                    >
                      {t('aide_page.faq.title')}
                    </h1>
                    <h1
                      style={{
                        fontSize: "25px",
                        color: "#434343",
                        textAlign: "left",
                      }}
                      className="vc_custom_heading contact-page-title hide"
                    >
                      B2B Maroc
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="vc_row wpb_row vc_row-fluid inner-top-xs">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    {t('aide_page.faq.sections', { returnObjects: true }).map((section, index) => (
                      <div
                        key={index}
                        id={`faq-${index}`}
                        className="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md"
                      >
                        <div style={{ cursor: 'pointer' }} className="vc_toggle_title">
                          <h4>{section.question}</h4>
                          <i className="vc_toggle_icon"></i>
                        </div>

                        <div style={{ display: 'none' }} className="vc_toggle_content">
                          {section.answer.map((paragraph, paragraphIndex) => {
                            // Special handling for paragraphs with interpolation
                            if (paragraph.includes('{linkdin}')) {
                              return (
                                <p key={paragraphIndex}>
                                  {paragraph.split('{linkdin}').map((part, index) =>
                                    index === 0 ? part : (
                                      <>
                                        <a href={JSON.parse(localStorage.getItem("information-owner"))?.linkdin || ''}>
                                          {JSON.parse(localStorage.getItem("information-owner"))?.linkdin || ''}
                                        </a>
                                        {part}
                                      </>
                                    )
                                  )}
                                </p>
                              );
                            }
                            if (paragraph.includes('{premiumLink}')) {
                              return (
                                <p key={paragraphIndex}>
                                  {paragraph.split('{premiumLink}').map((part, index) =>
                                    index === 0 ? part : (
                                      <>
                                        <Link to="/nos-packs-premium">
                                          <span style={{ color: "#ff9900" }}>
                                            <strong>Premium</strong>
                                          </span>
                                        </Link>
                                        {part}
                                      </>
                                    )
                                  )}
                                </p>
                              );
                            }
                            if (paragraph.includes('{contactLink}')) {
                              return (
                                <p key={paragraphIndex}>
                                  {console.log(paragraph.split('{contactLink}'))}
                                  {paragraph.split('{contactLink}').map((part, index) =>
                                    index === 0 ? part : (
                                      <>
                                        <Link to="/aide">{t('aide_page.faq.contact')}</Link>
                                        {part}
                                      </>
                                    )
                                  )}
                                </p>
                              );
                            }
                            return <p key={paragraphIndex}>{paragraph}</p>;
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <ContactForm t={t} />
          </div>
        </article>
      </main>
    </div>
  );
};

export default AidePage;
