import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AnnoncesAndOffres, Loading, ResultsInfo } from "./";
import { useTranslation } from "react-i18next";

const AnnouncesOption = ({
  getAnnonces,
  annonces,
  header,
  totalPages,
  TotalResults,
  isFiltred,
  isCityFiltred,
  ville,
  maxPrice,
  minPrice,
  ItemsPerPage,
  IsLoading,
  CompRef,
  setScrolling,
  query
}) => {

  const { t } = useTranslation();

  const sortType = [
    {
      id: 1,
      value: "default",
      title: t("annonces.sort.default"),
    },
    {
      id: 2,
      value: "mostSell",
      title: t("annonces.sort.popularity"),
    },
    {
      id: 3,
      value: "recent",
      title: t("annonces.sort.newest"),
    },
    {
      id: 4,
      value: "priceAsc",
      title: t("annonces.sort.price_asc"),
    },
    {
      id: 5,
      value: "priceDesc",
      title: t("annonces.sort.price_desc"),
    },
  ];

  const { category, subcategory } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [PageChanged, setPageChanged] = useState(false);
  const [sortWith, setSortWith] = useState("default");
  const [FirstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "js-comp-ver-6.8.0",
      "vc_responsive",
      "customize-support",
      "woocommerce-js",
      "translatepress-fr_FR",
      "left-sidebar",
      "wpb-js-composer",
      "logged-in",
      "admin-bar",
      "theme-electro",
      "woocommerce",
      "woocommerce-page",
      "normal",
      "tax-product_cat",
      "term-materiel-electrique-electronique-optique",
      "term-15",
      "archive"
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "js-comp-ver-6.8.0",
        "vc_responsive",
        "customize-support",
        "woocommerce-js",
        "translatepress-fr_FR",
        "left-sidebar",
        "wpb-js-composer",
        "logged-in",
        "admin-bar",
        "theme-electro",
        "woocommerce",
        "woocommerce-page",
        "normal",
        "tax-product_cat",
        "term-materiel-electrique-electronique-optique",
        "term-15",
        "archive"
      );
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1)
    getAnnonces('', '', '', 'default', 1);
    document.body.scrollIntoView({ behavior: 'smooth' });
    setFirstLoad(false)
  }, [query])

  useEffect(() => {

    if(!FirstLoad){
      getAnnonces(
        isFiltred ? maxPrice : '', 
        isFiltred ? minPrice : '', 
        isCityFiltred ? ville : '',
        sortWith, 
        currentPage
      );
    }

  }, [PageChanged, category, subcategory]);

  const goToPreviousPage = () => {
    setScrolling(true)
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    setPageChanged(!PageChanged);
  };

  const goToNextPage = () => {
    setScrolling(true)
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    setPageChanged(!PageChanged);
  };

  const goToPage = (page) => {
    setScrolling(true)
    if (page > currentPage) {
      setCurrentPage((prevPage) =>
        Math.min(prevPage + page - currentPage, totalPages)
      );
      setPageChanged(!PageChanged);

    } else if (page < currentPage) {
      let pageNumber = currentPage - page;
      setCurrentPage((prevPage) => Math.min(prevPage - pageNumber, totalPages));
      setPageChanged(!PageChanged);

    }
  };

  const renderPages = () => {
    const maxPagesVisible = 4;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i <= maxPagesVisible ||
        i == currentPage ||
        i + 1 == currentPage ||
        i - 1 == currentPage ||
        i == totalPages
      ) {
        pages.push(
          <li key={i}>
            <a
              onClick={() => goToPage(i)}
              style={{
                cursor:'pointer',
              }}
              className={`page-numbers ${currentPage == i ? "current" : ""}`}
            >
              {i}
            </a>
          </li>
        );
      } else if (pages[pages.length - 1] != "...") {
        pages.push("...");
      }
    }

    return pages;
  };

  const hundelChangeSelect = (e) => {
    e.preventDefault();

    setSortWith(e.target.value);
    setCurrentPage(1);
    setPageChanged(!PageChanged);
  };

  const openSideFilter = () => {
    document.querySelector("#content").classList.add("active-hh-sidebar");
  };

  return (

    <main id="main" className="site-main">
      
      {IsLoading && (
        <Loading
          style={{
            padding: "75px 0",
            height: "100%",
          }}
        />
      )}

      {annonces?.length == 0 && !IsLoading && (
        <div className="woocommerce-no-products-found">
          <div className="woocommerce-info">
            {t('annonces.messages.no_results')}
          </div>
        </div>
      )}

      <div ref={CompRef}>
        {annonces?.length > 0 && !IsLoading && (
          <>
            <div className="woocommerce-notices-wrapper"></div>

            <header className="page-header">
              <h1 className="page-title">{header}</h1>

              <ResultsInfo
                currentPage={currentPage}
                ItemsPerPage={ItemsPerPage}
                TotalResults={TotalResults}
                annonces={annonces}
                totalPages={totalPages}
              />
            </header>

            <div className="shop-control-bar">
              <div className="handheld-sidebar-toggle">
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "15px",
                  }}
                  onClick={() => openSideFilter()}
                  className="btn sidebar-toggler"
                  type="button"
                >
                  <i className="fas fa-sliders-h"></i>
                  <span>{t('annonces.filters.title')}</span>
                </button>
              </div>{" "}
              <form className="woocommerce-ordering" method="get">
                <select
                  defaultValue={sortWith}
                  name="orderby"
                  className="orderby"
                  aria-label="Commande"
                  onChange={(e) => hundelChangeSelect(e)}
                >
                  {sortType?.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.title}
                    </option>
                  ))}
                </select>
                <input type="hidden" name="paged" value="1" />
              </form>
              {totalPages > 1 && (
                <nav className="electro-advanced-pagination">
                  {currentPage > 1 && (
                    <button
                      className="prev page-numbers costum-page-numbers"
                      onClick={(e) => goToPreviousPage()}
                    >
                      ←
                    </button>
                  )}
                  <form method="post" className="form-adv-pagination">
                    <input
                      id="goto-page"
                      min={1}
                      max={totalPages}
                      step="1"
                      type="number"
                      className="form-control"
                      value={currentPage}
                    />
                  </form>{" "}
                  of {totalPages}
                  {totalPages > 1 && (
                    <button
                      className="next page-numbers costum-page-numbers"
                      onClick={() => goToNextPage()}
                    >
                      →
                    </button>
                  )}
                </nav>
              )}
            </div>

            <AnnoncesAndOffres annonces={annonces} />

            {totalPages > 1 && (
              <div className="shop-control-bar-bottom">
                <ResultsInfo
                  currentPage={currentPage}
                  ItemsPerPage={ItemsPerPage}
                  TotalResults={TotalResults}
                  annonces={annonces}
                  totalPages={totalPages}
                />

                <nav className="woocommerce-pagination">
                  <ul className="page-numbers">{renderPages()}</ul>
                </nav>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default AnnouncesOption;
