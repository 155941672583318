import React from "react";
import { useTranslation } from "react-i18next";
import { RecentAnnonce } from "../../components";
import { SideAnnonceDetails1Bg } from "../../constants/images";
import { SideAnnonceDetails2Bg } from "../../constants/images";
import { SideAnnonceDetails3Bg } from "../../constants/images";

const SideAnnonceDetails = () => {
  const { t } = useTranslation();
  
  const closeSideBar = () => {
    document.querySelector('#content').classList.remove('active-hh-sidebar')
  }
  
  return (
    <div id="sidebar" className="sidebar" role="complementary">
      <h3 style={{
        display:'flex',
        justifyContent :'flex-end',
        alignItems : 'end'
      }}>
        <i 
          style={{cursor:'pointer'}} 
          onClick={() => closeSideBar()} 
          className="fas fa-times closeVisible"
          title={t('side_annonce_details.close_sidebar')}
        ></i>
      </h3>

      <aside id="media_image-4" className="widget widget_media_image">
        <img
          width="270"
          height="428"
          src={SideAnnonceDetails1Bg}
          className="image wp-image-6706 attachment-full size-full"
          alt=""
          style={{ maxWidth: "100%", height: "auto" }}
          decoding="async"
          loading="lazy"
          srcSet={`${SideAnnonceDetails1Bg} 1x, ${SideAnnonceDetails2Bg} 2x, ${SideAnnonceDetails3Bg} 3x`}
          sizes="(max-width: 270px) 100vw, 270px"
        />
      </aside>

      <RecentAnnonce />
    </div>
  );
};

export default SideAnnonceDetails;
