import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { axiosClient } from "../../constants/axionsClient";
import { Loading } from '../'

const Product = ({ item }) => {
  const { title, ref, category, price, photo } = item;
  return (
    <li className="product type-product post-2599 status-publish first instock product_cat-materiel-electrique-electronique-optique shipping-taxable purchasable product-type-simple">
      <div className="product-outer product-item__outer">
        <div className="product-inner product-item__inner">
          <div className="product-loop-header product-item__header">
            <span className="loop-product-categories">
              <Link to={`/product-category/${category}`} rel="tag">
                {category}
              </Link>
            </span>
            <Link
              to={`/product/${ref}`}
              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
            >
              <h2 className="woocommerce-loop-product__title">{title}</h2>
              <div className="product-thumbnail product-item__thumbnail">
                <img
                  width="300"
                  height="300"
                  src={
                    photo ||
                    "https://www.b2bmaroc.com/wp-content/uploads/woocommerce-placeholder-300x300.png"
                  }
                  className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                  alt={title}
                />
              </div>
            </Link>
          </div>

          <div className="product-loop-footer product-item__footer">
            <div className="price-add-to-cart">
              <span className="price">
                <span className="electro-price">
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      {price}
                      <span className="woocommerce-Price-currencySymbol">
                        DH
                      </span>
                    </bdi>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

const CompanyAnnonces = ({ number }) => {
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState(1);
  const productContain = useRef(null)
  const [Annonces, setAnnonces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [IsLoading, setIsLoading] = useState(true);
  const [PageChanged, setPageChanged] = useState(true);

  useEffect(() => {
    (async () => {

      setIsLoading(true);

      try {
        const res = await axiosClient.get(
          `company-announces/${number}/${currentPage}`
        );

        if (res.status === 200) {
          setAnnonces(res.data.announcements);
          setTotalPages(res.data.total_pages);
        }

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    })();
  }, [PageChanged]);

  const goToPage = (page) => {
    
    if (page > currentPage) {
      setCurrentPage((prevPage) =>
        Math.min(prevPage + page - currentPage, totalPages)
      );
      setPageChanged(!PageChanged);
    } else if (page < currentPage) {
      let pageNumber = currentPage - page;
      setCurrentPage((prevPage) => Math.min(prevPage - pageNumber, totalPages));
      setPageChanged(!PageChanged);
    }
  };

  const renderPages = () => {
    const maxPagesVisible = 4;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i <= maxPagesVisible ||
        i == currentPage ||
        i + 1 == currentPage ||
        i - 1 == currentPage ||
        i == totalPages
      ) {
        pages.push(
          <li key={i}>
            <a
              onClick={() => goToPage(i)}
              style={{
                cursor: "pointer",
              }}
              className={`page-numbers ${currentPage == i ? "current" : ""}`}
            >
              {i}
            </a>
          </li>
        );
      } else if (pages[pages.length - 1] != "...") {
        pages.push("...");
      }
    }

    return pages;
  };

  return (
    <section className="w-100 mb-0" id="produits">
      <header className="mb-2 mt-2">
        <h2 className="h1">{t('company_annonces.all_products')}</h2>
      </header>
        {
            IsLoading ? <Loading style={{padding : '150px 0'}} /> : (
                <>
                    <div className="woocommerce columns-5 ">
                        <ul
                            dataView="grid"
                            dataBsToggle="regular-products"
                            className="products products list-unstyled row g-0 row-cols-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5 row-cols-xxl-5"
                        >
                            {Annonces?.map((item) => (
                            <Product item={item} />
                            ))}
                        </ul>
                    </div>
                    {
                      totalPages > 1 && (
                        <nav className="woocommerce-pagination">
                            <ul style={{
                                justifyContent : 'center',
                                padding : '10px 0',
                            }} className="page-numbers">
                                {renderPages()}
                            </ul>
                        </nav>
                      )
                    }
                </>
            )
        }

        {
          Annonces?.length == 0 && <p style={{textAlign:'center'}}>{t('company_annonces.no_announces')}</p>
        }

    </section>
  );
};

export default CompanyAnnonces;
