import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosClient } from "../../constants/axionsClient";
import { Loading } from "../";
import { useTranslation } from "react-i18next";

const Announce = ({ item }) => {
  const { t } = useTranslation();

  const {
    ref, title, photo, price
  } = item

  return (
    <li key={ref}>
      <Link to={`/product/${ref}`}>
        <img
          width="1"
          height="1"
          src={photo || product}
          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
          alt=""
          decoding="async"
          loading="lazy"
        />
        <span className="product-title">{title}</span>
      </Link>
      <span className="electro-price">
        <span className="woocommerce-Price-amount amount">
          <bdi>{price}
            <span className="woocommerce-Price-currencySymbol">DH </span>
          </bdi>
        </span>
      </span>
    </li>
  )
}

const RecentAnnonce = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([])
  const [IsLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosClient.get("/recent-announces")

        if (res.status === 200) {
          setProducts(res.data)
        }
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false)
    })();
  }, [])

  return (
    <aside
      id="woocommerce_products-2"
      className="widget woocommerce widget_products"
    >
      <h3 className="widget-title">{t('recent_announcements.title')}</h3>

      {IsLoading && (
        <Loading
          style={{
            padding: "75px 0",
            height: "100%",
          }}
        />
      )}

      {products?.length == 0 && !IsLoading && (
        <div className="woocommerce-no-products-found">
          <div className="woocommerce-info">
            {t('recent_announcements.no_announcements')}
          </div>
        </div>
      )}

      <ul className="product_list_widget">
        {products?.map((item) => (
          <Announce item={item} />
        ))}
      </ul>
    </aside>
  );
};

export default RecentAnnonce;
