import React, { useEffect, useState } from 'react'
import { BasicPack, Loading, NosPaiements } from '../../components';
import Plans_1 from '../../components/Plans/Plans_1'
import { axiosClient } from '../../constants/axionsClient';
import { PlansBg } from '../../constants/images'

const PlansPage = () => {

    const [plans, setPlans] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {     
           
        document.body.removeAttribute("class");
        document.body.classList.add(
          "page-template-default",
          "page",
          "page-id-5632",
          "theme-electro",
          "woocommerce-js",
          "translatepress-fr_FR",
          "no-breadcrumb",
          "wpb-js-composer",
          "js-comp-ver-6.8.0",
          "vc_responsive"
        );
    
        // Cleanup on component unmount
        return () => {
          document.body.classList.remove(
            "page-template-default",
            "page",
            "page-id-5632",
            "theme-electro",
            "woocommerce-js",
            "translatepress-fr_FR",
            "no-breadcrumb",
            "wpb-js-composer",
            "js-comp-ver-6.8.0",
            "vc_responsive"
          );
        }
    
      }, []);

    useEffect(() => {

      (async() => {
        setIsLoading(true)
        try {
          
          const res = await axiosClient.get('/plans')

          if(res.status === 200){

            setPlans(res.data)

          }

        } catch (error) {
          
          console.log(error);

        }
        setIsLoading(false)

      })()

    }, [])

  return (
    <div id="primary" className="content-area">
      <main id="main" className="site-main">
        <article id="post-5632" className="post-5632 page type-page status-publish has-post-thumbnail hentry">
            
            <header
                className="entry-header header-with-cover-image"
                style={{
                backgroundImage: `url(${PlansBg})`,
                }}
            >
                <div className="caption">
                <h1 className="entry-title"></h1>
                </div>
            </header>
            {

              isLoading ?
                <Loading style={{padding : '50px 0'}}/>
              :
              (
                <div style={{padding : '20px 0 0'}} className='entry-content'>
    
                    <Plans_1 plans={plans}/>
                    <div className="vc_row-full-width vc_clearfix"></div>
                    <BasicPack plan={plans?.find(item => item.type == 'Basic' )} />
                    <div className="vc_row-full-width vc_clearfix"></div>
                    <NosPaiements/>
    
                </div>
              )

            }

        </article>
      </main>
    </div>
  )
}

export default PlansPage