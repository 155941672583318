import React from 'react';
import { useTranslation } from "react-i18next";

const PriceFilter = ({
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    handleSubmit,
    hundelReset
}) => {
    const { t } = useTranslation();

    const hundelChange = (e, action) => {

        if(action == 'max'){
            if(
              parseInt(e.target.value) ||
              e.target.value == 0
            ){
                setMaxPrice(e.target.value)
            }

        }

        if(action == 'min'){

          if(
            parseInt(e.target.value) ||
            e.target.value == 0
          ){
              setMinPrice(e.target.value)
          }

        }

    }

  return (
    <aside id="woocommerce_price_filter-1" className="widget woocommerce widget_price_filter">
      <h3 className="widget-title">{t('annonces.filters.price.title')}</h3>
      <form onSubmit={(e) => handleSubmit(e, 'price')}>
        <div className="price_slider_wrapper">
          <div style={{width:'100%'}} className="price_slider_amount" data-step="10">
            <label className="screen-reader-text" htmlFor="min_price">{t('annonces.filters.price.min_price')}</label>
            <input
              type="text"
              id="min_price"
              name="min_price"
              value={minPrice}
              onChange={(e) => hundelChange(e, 'min')}
            />
            <label className="screen-reader-text" htmlFor="max_price">{t('annonces.filters.price.max_price')}</label>
            <input
                type="text"
                style={{
                    margin : '10px 0'
                }}
                id="max_price"
                name="max_price"
                onChange={(e) => hundelChange(e, 'max')}
                value={maxPrice}
            />
            <div style={{
              display: 'flex',
              flexWrap : 'wrap',
              marginBottom : '5px',
              gap : '5px'
            }}>
              <button type="submit" className="button">{t('annonces.filters.price.filter')}</button>
              <button onClick={(e) => hundelReset(e, 'price')} className="button">{t('annonces.filters.price.reset')}</button>
            </div>
            <div className="price_label">
              {t('annonces.filters.price.price_label', { min: minPrice, max: maxPrice })}
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </form>
    </aside>
  );
};

export default PriceFilter;
