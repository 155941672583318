import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({
  step_1, step_2, step_3
}) => {
  return (
    <nav className="woocommerce-breadcrumb" aria-label="Breadcrumb">
      {
        step_1 && (
          <Link to="/">
            {
              step_1
            }
          </Link>
        )
      }
      {
        step_2 && (
          <>
            <span className="delimiter">
              <i className="fa fa-angle-right"></i>
            </span>
            <Link to={`/product-category/${step_2}/`}>
              {
                step_2
              }
            </Link>
          </>
        )
      }
      {
        step_3 && (
          <>
            <span className="delimiter">
              <i className="fa fa-angle-right"></i>
            </span>
            {
                step_3
              }
          </>
        )
      }
    </nav>
  );
};

export default NavLink;
