import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import routes from "./routes"
import { Toaster } from 'react-hot-toast';
import './styles/main.scss'
import './i18n';

const root = ReactDOM.createRoot(document.querySelector("#page"))

root.render(
    <React.StrictMode>
        <RouterProvider router={routes} />
        <Toaster />
    </React.StrictMode>
)
