import React from 'react'
import { useTranslation } from 'react-i18next';

const BasicPack = ({plan}) => {
  const { t } = useTranslation();
  
  return (
    <section
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      className="vc_section vc_custom_1717176634876 vc_section-has-fill"
      style={{
        position: 'relative',
        boxSizing: "border-box"
      }}
    >
        <div>
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176711924">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                    <h2
                        style={{
                        fontSize: "36px",
                        color: "#434343",
                        textAlign: "left"
                        }}
                        className="vc_custom_heading contact-page-title"
                    >
                        {t('plans.basic.title')}
                    </h2>
                    </div>
                </div>
                </div>
            </div>
            <div className="vc_row wpb_row vc_row-fluid inner-bottom-xs vc_custom_1717175111422 vc_row-has-fill">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1646912088974">
                    <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element">
                        <div className="wpb_wrapper">
                        <p style={{ textAlign: "justify", fontSize: "14px" }}>
                            {t('plans.basic.services_included')}
                        </p>
                        <ul>
                            {t('plans.basic.features', {
                              returnObjects: true,
                              max_announcements: plan?.max_announcements || t('common.unlimited'),
                              max_update: plan?.max_update || t('common.unlimited'),
                              max_pinned_announcements: plan?.max_pinned_announcements || t('common.unlimited')
                            }).map((feature, index) => (
                              <li key={index} style={{ textAlign: "justify", fontSize: index === 7 ? "13px" : "14px" }}>
                                {feature}
                              </li>
                            ))}
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BasicPack