import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AvantagesBg } from '../../constants/images'

const Button = ({text}) => {
    return (
        <div className='avantages-buttons' >
            <Link  
            to="/auth/login"
            title="Lien pour créer votre page"
            >
            {text}{" "}
            <i className="fa-solid fa-right-long"></i>
            </Link>
      </div>
    )
}

const Avantages = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "home",
      "page-template-default",
      "page-id-79",
      "page",
      "wp-custom-logo",
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "home",
        "page",
        "page-template-default",
        "page",
        "page-id-79",
        "wp-custom-logo",
      );
    };
  }, []); 

  return (
    <div id="primary" className="content-area">
  <main id="main" className="site-main">
    <article
      id="post-10"
      className="post-10 page type-page status-publish has-post-thumbnail hentry"
    >
      <header
        className="entry-header header-with-cover-image"
        style={{
          backgroundImage:
            `url(${AvantagesBg})`,
        }}
      >
        <div className="caption">
          <h1 className="entry-title"> </h1>
        </div>
      </header>

      <div className="entry-content">
        <div style={{paddingTop:'100px'}} className="vc_row wpb_row vc_row-fluid vc_custom_1646837983055">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <h2 className="contact-page-title" style={{ textAlign: "left" }}>
                      {t('avantages.page_title')}
                    </h2>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      {t('avantages.first_paragraph')}
                    </p>
                    <p>{t('avantages.first_list_intro')}</p>
                    <ul>
                      {t('avantages.first_list', { joinArrays: '</li><li>' }).split('</li><li>').map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      {t('avantages.premium_first_paragraph')}
                    </p>
                  </div>
                </div>

                <Button text={t('avantages.button_create_page')}/>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      {t('avantages.premium_first_paragraph')}
                    </p>
                    <p>{t('avantages.premium_list_intro')}</p>
                    <ul>
                      {t('avantages.premium_list', { joinArrays: '</li><li>' }).split('</li><li>').map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <Button text={t('avantages.button_see_premium_packs')} />

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      {t('avantages.last_paragraph')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
</div>

  )
}

export default Avantages