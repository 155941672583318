import React from "react";
import {RecentAnnonce, CityFilter} from "../";
import PriceFilter from "./PriceFilter";
import { FilterSideBg } from "../../constants/images";
import { useTranslation } from "react-i18next";

const FilterSide = ({
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    handleSubmit,
    hundelReset,
    ville,
    setVille,
    setSelectedCountry
}) => {
    const { t } = useTranslation();

  const closeSideBar = () => {
    document.querySelector('#content').classList.remove('active-hh-sidebar')
  }

  return (
    <div id="sidebar" className="sidebar" role="complementary">
      <aside
        id="electro_products_filter-1"
        className="widget widget_electro_products_filter"
      >
          <h3 style={{
            display:'flex',
            justifyContent :'space-between',
            alignItems : 'center'
          }}className="widget-title">
            {t('annonces.filters.title')} <i style={{cursor:'pointer'}} onClick={() => closeSideBar()} className="fas fa-times closeVisible"></i>
          </h3>

        <CityFilter 
            ville={ville}
            setVille={setVille}  
            setSelectedCountry={setSelectedCountry}
            handleSubmit={handleSubmit}
            hundelReset={hundelReset}
        />

        <PriceFilter 
            maxPrice={maxPrice}
            minPrice={minPrice}
            setMinPrice={setMinPrice}    
            setMaxPrice={setMaxPrice}    
            handleSubmit={handleSubmit}
            hundelReset={hundelReset}
        />

      </aside>

      <aside id="media_image-4" className="widget widget_media_image">
          <img
            width="270"
            height="428"
            src={FilterSideBg}
            className="image wp-image-6706 attachment-full size-full"
            alt=""
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </aside>

        <RecentAnnonce/>

    </div>
  );
};

export default FilterSide;
