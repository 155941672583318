import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Loading, ResultsInfo} from '../'
import { logo } from "../../constants/images";

const FournisseurItem = ({info}) => {
    const { id, name, type_company, photo } = info;

  return (
    <li
      key={id}
      className="product type-product post-6972 status-publish first instock product_cat-construction-batiment-bois-habitat has-post-thumbnail shipping-taxable product-type-simple"
    >
      <div
        className="product-outer product-item__outer"
        style={{ height: "342.898px" }}
      >
        <div className="product-inner product-item__inner">
          <div className="product-loop-header product-item__header">
            <Link
              to={`/fournisseurs/${id}`}
              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
            >
              <h2 className="woocommerce-loop-product__title">{name}</h2>
              <div className="product-thumbnail product-item__thumbnail">
                <img
                  width="300"
                  height="300"
                  src={photo || logo}
                  className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                  alt={name}
                  loading="lazy"
                />
              </div>
            </Link>
          </div>
          <div className="product-loop-body product-item__body">
            <span className="loop-product-categories">
                {type_company}
            </span>
            <Link
              to={`/fournisseurs/${id}`}
              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
            >
              <h2 className="woocommerce-loop-product__title">{name}</h2>
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
}

const FournisseurItems = ({Companies}) => {
    return (
        <ul
            data-view="list-view"
            data-bs-toggle="regular-products"
            className="products products list-unstyled row g-0 row-cols-2 row-cols-md-3 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-5"
        >
            {
                Companies && Companies?.map((item) => (
                    <FournisseurItem info={item} />
                ))
            }
        </ul>
    )
}

const FournisseurContent = ({
  getCompanies,
  Companies,
  header,
  totalPages,
  TotalResults,
  IsLoading,
  CompRef,
  query,
  setScrolling,
  ItemsPerPage,
  sortWith,
  setSortWith
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [PageChanged, setPageChanged] = useState(false);
  const [FirstLoad, setFirstLoad] = useState(true)

  const sortType = [
    {
      id: 1,
      value: "sells",
      title: t('fournisseurs.content.sort_types.0.title'),
    },
    {
      id: 2,
      value: "date",
      title: t('fournisseurs.content.sort_types.1.title'),
    },
  ];

  useEffect(() => {
    setCurrentPage(1)
    getCompanies(1)
    document.body.scrollIntoView({ behavior: 'smooth' });
    setFirstLoad(false)
  }, [query])

  useEffect(() => {
    if(!FirstLoad){
      getCompanies(currentPage, sortWith);
    }
  }, [PageChanged]);

  const goToPreviousPage = () => {
    setScrolling(true);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    setPageChanged(!PageChanged);
  };

  const goToNextPage = () => {
    setScrolling(true);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    setPageChanged(!PageChanged);
  };

  const goToPage = (page) => {
    setScrolling(true);
    if (page > currentPage) {
      setCurrentPage((prevPage) =>
        Math.min(prevPage + page - currentPage, totalPages)
      );
      setPageChanged(!PageChanged);
    } else if (page < currentPage) {
      let pageNumber = currentPage - page;
      setCurrentPage((prevPage) => Math.min(prevPage - pageNumber, totalPages));
      setPageChanged(!PageChanged);
    }
  };

  const renderPages = () => {
    const maxPagesVisible = 4;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i <= maxPagesVisible ||
        i == currentPage ||
        i + 1 == currentPage ||
        i - 1 == currentPage ||
        i == totalPages
      ) {
        pages.push(
          <li key={i}>
            <a
              onClick={() => goToPage(i)}
              style={{
                cursor: "pointer",
              }}
              className={`page-numbers ${currentPage == i ? "current" : ""}`}
            >
              {i}
            </a>
          </li>
        );
      } else if (pages[pages.length - 1] != "...") {
        pages.push("...");
      }
    }

    return pages;
  };

  const hundelChangeSelect = (e) => {
    e.preventDefault();

    setSortWith(e.target.value);
    setCurrentPage(1);
    setPageChanged(!PageChanged);
  };

  const openSideFilter = () => {
    document.querySelector("#content").classList.add("active-hh-sidebar");
  };

  return (
    <>
      {IsLoading && (
        <Loading
          style={{
            padding: "75px 0",
            height: "100%",
          }}
        />
      )}

      {Companies?.length == 0 && !IsLoading && (
        <div className="woocommerce-no-products-found">
          <div className="woocommerce-info">
            {t('fournisseurs.content.no_results')}
          </div>
        </div>
      )}

      <div ref={CompRef}>
        {Companies?.length > 0 && !IsLoading && (
          <>
            <div className="woocommerce-notices-wrapper"></div>

            <header className="page-header">
              <h1 className="page-title">{header}</h1>

                <ResultsInfo
                    currentPage={currentPage}
                    ItemsPerPage={ItemsPerPage}
                    TotalResults={TotalResults}
                    annonces={Companies}
                    totalPages={totalPages}
                />

            </header>

            <div className="shop-control-bar">
              <div className="handheld-sidebar-toggle">
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "15px",
                  }}
                  onClick={() => openSideFilter()}
                  className="btn sidebar-toggler"
                  type="button"
                >
                  <i className={`fa-solid fa-${t('fournisseurs.content.recommended_suppliers_button.icon')}`}></i>
                  <span>{t('fournisseurs.content.recommended_suppliers_button.text')}</span>
                </button>
              </div>{" "}
              <form className="woocommerce-ordering" method="get">
                <select
                  defaultValue={sortWith}
                  name="orderby"
                  className="orderby"
                  aria-label="Commande"
                  onChange={(e) => hundelChangeSelect(e)}
                >
                  {sortType?.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.title}
                    </option>
                  ))}
                </select>
                <input type="hidden" name="paged" value="1" />
              </form>
              {totalPages > 1 && (
                <nav className="electro-advanced-pagination">
                  {currentPage > 1 && (
                    <button
                      className="prev page-numbers costum-page-numbers"
                      onClick={(e) => goToPreviousPage()}
                    >
                      {t('fournisseurs.content.pagination.previous')}
                    </button>
                  )}
                  <form method="post" className="form-adv-pagination">
                    <input
                      id="goto-page"
                      min={1}
                      max={totalPages}
                      step="1"
                      type="number"
                      className="form-control"
                      value={currentPage}
                    />
                  </form>{" "}
                  {t('fournisseurs.content.pagination.of')} {totalPages}
                  {totalPages > 1 && (
                    <button
                      className="next page-numbers costum-page-numbers"
                      onClick={() => goToNextPage()}
                    >
                      {t('fournisseurs.content.pagination.next')}
                    </button>
                  )}
                </nav>
              )}
            </div>

            <FournisseurItems Companies={Companies} />

            {totalPages > 1 && (
              <div className="shop-control-bar-bottom">
                <ResultsInfo
                  currentPage={currentPage}
                  ItemsPerPage={ItemsPerPage}
                  TotalResults={TotalResults}
                  annonces={Companies}
                  totalPages={totalPages}
                />

                <nav className="woocommerce-pagination">
                  <ul className="page-numbers">{renderPages()}</ul>
                </nav>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FournisseurContent;
