import React from 'react'
import { useTranslation } from 'react-i18next';

const NosPaiements = () => {
  const { t } = useTranslation();

  return (
    <section
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      className="vc_section vc_custom_1717176837281"
      style={{
        position: "relative",
        boxSizing: "border-box"
      }}
    >
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176749054">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <h2
                style={{
                  fontSize: "36px",
                  color: "#434343",
                  textAlign: "left"
                }}
                className="vc_custom_heading contact-page-title"
              >
                {t('payments.title')}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176900103">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <ul>
                    <li><strong>{t('payments.credit_card.title')}</strong></li>
                  </ul>
                  <p>{t('payments.credit_card.description')}</p>
                  
                  <ul>
                    <li><strong>{t('payments.cash_on_delivery.title')}</strong></li>
                  </ul>
                  <p>{t('payments.cash_on_delivery.description')}</p>
                  
                  <ul>
                    <li><strong>{t('payments.bank_transfer.title')}</strong></li>
                  </ul>
                  <p>{t('payments.bank_transfer.description')}</p>
                  
                  <p>
                    {t('payments.more_info', {
                      contact_us: `<a href="https://www.b2bmaroc.com/contactez-nous/">contactez-nous</a>`
                    })}
                  </p>
                  
                  <p>&nbsp;</p>
                  
                  <p>
                    <em>{t('payments.advanced_advertising.title')}{" "}
                    <a href="http://b2bmaroc.com/contactez-nous/">
                      <em>{t('payments.advanced_advertising.cta')}</em>
                    </a>
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NosPaiements