import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isLogged } from "../constants/AuthFunctions";
import { product } from "../constants/images";
import { ActionListSouhaits } from './'

const Annonce = ({ info, isSim}) => {
  const { t } = useTranslation();
  const { title, ref, category, price, photo, in_wishlist, company_id } = info;
  const [auth, setAuth] = useState(isLogged());

  useEffect(() => {
      setAuth(isLogged());
  }, []);

  return (
    <li
      key={ref}
      className="product type-product post-6972 status-publish first instock product_cat-construction-batiment-bois-habitat has-post-thumbnail shipping-taxable product-type-simple"
    >
      <div
        className="product-outer product-item__outer"
        style={{ height: "342.898px" }}
      >
        <div className="product-inner product-item__inner">
          <div className="product-loop-header product-item__header">
            <span className="loop-product-categories">
              <Link
                to={`/product-category/${category}`}
                rel="tag"
              >
                {category}
              </Link>
            </span>
            <Link
              to={`/product/${ref}`}
              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
            >
              <h2 className="woocommerce-loop-product__title">{title}</h2>
              <div className="product-thumbnail product-item__thumbnail">
                <img
                  width="300"
                  height="300"
                  src={photo || product}
                  className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                  alt={title}
                  loading="lazy"
                />
              </div>
            </Link>
          </div>
          <div className="product-loop-body product-item__body">
            <span className="loop-product-categories">
              <Link
                to={`/product-category/${category}`}
                rel="tag"
              >
                {category}
              </Link>
            </span>
            <Link
              to={`/product/${ref}`}
              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
            >
              <h2 className="woocommerce-loop-product__title">{title}</h2>
              <div className="product-sku">{t('announces.sku')}: {ref}</div>
            </Link>
          </div>
          <div className="product-loop-footer product-item__footer">
            <div className="price-add-to-cart">
              <span className="price">
                <span className="electro-price">{
                  price ? `${price} DH` : t('announces.price_negotiable')
                }</span>
              </span>

              <div
                className="add-to-cart-wrap"
                data-bs-toggle="tooltip"
                data-bs-title="Pour plus d'informations cliquez ici"
                title="Pour plus d'informations cliquez ici"
              >
                <Link
                  to={`/product/${ref}`}
                  className="button mt-2 mb-2 product_type_simple"
                >
                  {t('announces.view_announcement')}
                </Link>
                
                {!isSim && <Link
                  to={`/fournisseurs/${company_id}`}
                  className="button button bg-warning"
                >
                  {t('announces.contact_supplier')}
                </Link>}
              </div>
              <span
                id="woocommerce_loop_add_to_cart_link_describedby_6972"
                className="screen-reader-text"
              ></span>
            </div>
            
            {
              auth && (
                <div className="hover-area">
                  <ActionListSouhaits announce_ref={ref} is_added_status={in_wishlist} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </li>
  );
};

export default Annonce;
