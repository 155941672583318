import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Experimental_Device_Set, Page_A_Propos } from '../../constants/images';

const AproposNous = () => {
    const { t } = useTranslation();
    const information = JSON.parse(localStorage.getItem("information-owner"))
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <article
            id="post-2494"
            className="post-2494 page type-page status-publish has-post-thumbnail hentry"
        >
        <header
            className="entry-header header-with-cover-image"
            style={{
            backgroundImage:
                `url(${Page_A_Propos})`,
            }}
        >
            <div className="caption">
            <h1 className="entry-title"></h1>
            </div>
        </header>
        <div className="entry-content">
            <div style={{marginTop : '80px', lineHeight: 1.85}} className="vc_row wpb_row vc_row-fluid vc_custom_1649894513964">
            <div className="wpb_column vc_column_container vc_col-sm-6">
                <div className="vc_column-inner vc_custom_1646827090823">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element vc_custom_1687358971712">
                    <div className="wpb_wrapper">
                        <h2
                        className="contact-page-title"
                        style={{ textAlign: "left" }}
                        >
                        <strong>{t('apropos_page.title')}</strong>
                        </h2>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1687359029128">
                    <div className="wpb_wrapper">
                        <p 
                            style={{ textAlign: "justify", color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_1') }}
                        />
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1676489567202">
                    <div className="wpb_wrapper">
                        <p 
                            style={{ color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_2') }}
                        />
                        <p 
                            style={{ color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_3') }}
                        />
                        <p 
                            style={{ color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_4') }}
                        />
                        <p 
                            style={{ color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_5') }}
                        />
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1646830806265">
                    <div className="wpb_wrapper">
                        <p 
                            style={{ color: "#5e5873" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.description_6') }}
                        />
                    </div>
                    </div>
                    <div className="vc_btn3-container vc_btn3-inline vc_custom_1670244747240">
                    <Link
                        className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-outline vc_btn3-icon-right vc_btn3-color-orange"
                        to="/plans/"
                        title="Nos Packs PREMIUM"
                    >
                        {t('apropos_page.premium_button')}{" "}
                        <i className="vc_btn3-icon vc-material vc-material-arrow_forward"></i>
                    </Link>
                    </div>
                </div>
                </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-6">
                <div className="vc_column-inner vc_custom_1649893902329">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element vc_custom_1715647506018">
                    <div className="wpb_wrapper">
                        <p>&nbsp;</p>
                        <p 
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.economic_description') }}
                        />
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1716484384660">
                    <div className="wpb_wrapper">
                        <p 
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.special_packs_description') }}
                        />
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1716484408593">
                    <div className="wpb_wrapper">
                        <p 
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.blog_description_1') }}
                        />
                        <p 
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.blog_description_2') }}
                        />
                    </div>
                    </div>
                    <div className="vc_btn3-container vc_btn3-inline vc_custom_1670244734662">
                    <Link
                        className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-outline vc_btn3-icon-right vc_btn3-color-orange"
                        to="/nos-packs-premium"
                        title="Nos Packs PREMIUM"
                    >
                        {t('apropos_page.premium_button')}{" "}
                        <i className="vc_btn3-icon vc-material vc-material-arrow_forward"></i>
                    </Link>
                    </div>
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                    <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper vc_box_rounded vc_box_border_grey">
                        <img
                            fetchpriority="high"
                            decoding="async"
                            width="2560"
                            height="1920"
                            src={Experimental_Device_Set}
                            className="vc_single_image-img attachment-full"
                            alt="14-Experimental-Device-Set-Mockup"
                            title="14-Experimental-Device-Set-Mockup"
                        />
                        </div>
                    </figure>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="vc_row wpb_row vc_row-fluid">
            <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element">
                    <div className="wpb_wrapper">
                        <p 
                            dangerouslySetInnerHTML={{ __html: t('apropos_page.social_media_description') }}
                        />
                        <div className="footer-social-icons mt-3">
                                <ul style={{
                                    fontSize : '20px'
                                }} className="social-icons list-unstyled nav justify-content-md-start justify-content-center align-items-center">
                                    <li>
                                    <a
                                        className="fab fa-facebook"
                                        target="_blank"
                                        href={information?.facebook}
                                    ></a>
                                    </li>
                                    <li>
                                    <a
                                        className="fab fa-linkedin"
                                        target="_blank"
                                        href={information?.linkdin}
                                    ></a>
                                    </li>
                                    <li>
                                    <a
                                        className="fab fa-instagram"
                                        target="_blank"
                                        href={information?.instagram}
                                    ></a>
                                    </li>
                                </ul>
                            </div>
                    </div>{" "}
                    </div>{" "}
                </div>{" "}
                </div>{" "}
            </div>{" "}
            </div>{" "}
        </div>{" "}
        </article>
    );

};

export default AproposNous;
