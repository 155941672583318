import React, {useEffect} from "react";
import { NavLink } from "../../components";
import { useTranslation } from "react-i18next";

const Conditions = () => {

    const { t } = useTranslation();
    useEffect(() => {
        document.body.removeAttribute("class");
        document.body.classList.add(
          "home",
          "page-template-default",
          "page-id-79",
          "page",
          "wp-custom-logo",
        );
    
        // Cleanup on component unmount
        return () => {
          document.body.classList.remove(
            "home",
            "page",
            "page-template-default",
            "page",
            "page-id-79",
            "wp-custom-logo",
          );
        };
      }, []); 

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <>
        <div>
            <NavLink step_1={t('conditions_page.nav_link.step_1')} step_2={t('conditions_page.nav_link.step_2')} />
        </div>
        <div id="primary" className="content-area">
        <main id="main" className="site-main">
            <article
            id="post-3"
            className="post-3 page type-page status-publish hentry"
            >
            <header className="entry-header">
                <h1 className="entry-title">{t('conditions_page.title')}</h1>
            </header>
            <div style={{fontSize:'15px', lineHeight : 1.85}} className="entry-content">
                <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element">
                        <div style={{fontSize: '17px'}} className="wpb_wrapper">
                        {t('conditions_page.articles', { returnObjects: true }).map((article, index) => (
                                <React.Fragment key={index}>
                                    <p>
                                        <strong><u>{article.number}</u></strong>: <strong><u>{article.title}</u></strong>
                                    </p>
                                    {article.content.map((paragraph, pIndex) => (
                                        typeof paragraph === 'object' && paragraph.services ? (
                                            <ul key={pIndex}>
                                                {paragraph.services.map((service, sIndex) => (
                                                    <li key={sIndex}>{service}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p key={pIndex} dangerouslySetInnerHTML={{ __html: paragraph }} />
                                        )
                                    ))}
                                </React.Fragment>
                            ))}
                            <hr className="wp-block-separator has-alpha-channel-opacity" />
                            <em>
                                {t('conditions_page.sous_content_1')}
                            </em>
                            <p></p>
                            <p>
                            <em>
                            {t('conditions_page.sous_content_2')}
                            </em>{" "}
                            <a href="mailto:contact@b2bmaroc.com">
                                contact@b2bmaroc.com
                            </a>
                            </p>
                            <p>&nbsp;</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </article>
        </main>
        </div>
    </>
  );
};

export default Conditions;
