import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { axiosClient } from '../constants/axionsClient';
import { baseRoute } from '../constants/env';
import { loginImg } from '../constants/images';
import { useTranslation } from 'react-i18next';
import {dashboardRoute} from '../constants/env'

const Login = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('langue', lang)
    };

    const hundelClick = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await axiosClient.post("login", {
                email: email,
                password: password
            });

            if (res.status === 200) {
                const redirectedPath = sessionStorage.getItem('redirectPath');
                localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('token', res.data.access_token);
                window.location.href = redirectedPath || baseRoute;
            }
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(
                    Object.values(err.response.data.errors).map((item) => item[0])
                );

            } else if (err.response && err.response.status === 401) {

                setErrors([err.response.data.message])

            } else {
                setErrors([]);
            }
        }
        setIsLoading(false);
    }

    if (user) {
        return navigate(
            JSON.parse(localStorage.getItem('isAdmin')) ? 'dashboard' : 'company'
        );
    }

    return (
        <div className="login-container">

            <div className="left-section" style={{ backgroundColor: '#f5a741' }}>
                <img src={loginImg} alt="Illustration" />
            </div>
            <div className="right-section">
                <div style={{ position: 'relative' }} className="content-wrapper">

                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <select style={{
                            width: 'fit-content'
                        }} value={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
                            <option value="fr">FR</option>
                            <option value="en">EN</option>
                        </select>
                    </div>
                    <div className="text-center">
                        <h2 style={{ color: '#333e48' }}>{t('auth.login.title')}</h2>
                    </div>
                    <div className="form-group text-center">
                        <p>{t('auth.login.subtitle')}</p>
                    </div>
                    <form onSubmit={hundelClick}>
                        <input
                            className="form-group"
                            label={t('auth.login.email')}
                            type="email"
                            name="email"
                            placeholder={t('auth.login.email')}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <input
                            className="form-group"
                            label={t('auth.login.password')}
                            type="password"
                            name="password"
                            placeholder={t('auth.login.password')}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        <button disabled={isLoading} type="submit" className="button-submit">
                            {t('auth.login.submit')}
                        </button>

                        {errors.length > 0 && (
                            <ul className='errors-bar'>
                                {errors?.map((item, key) => (
                                    <li key={key}>{item}</li>
                                ))}
                            </ul>
                        )}
                    </form>
                    <div className="link-container">
                        {t('auth.login.no_account')}{" "}
                        <span className="link" onClick={() => window.location.href = `${dashboardRoute}/register`}>
                            {t('auth.login.join_us')}
                        </span>
                    </div>
                    <div className="link-container">
                        <a href="#" className="link">{t('auth.login.forgot_password')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;