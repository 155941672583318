import axios from "axios";
import { API } from "./env";

// Create an axios instance
export const axiosClient = axios.create({
  baseURL: API,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  headers: {
    Accept: "application/json",
    Authorization : localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ''
  },
});

// Axios request interceptor to set Authorization header dynamically
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Optional: Global error logging in response interceptor
axiosClient.interceptors.response.use(null, (err) => {
  console.log(err);
  return Promise.reject(err);
});
