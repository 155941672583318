import React from 'react';
import {Annonce, Loading} from '../'

const AnnouncementsAndOffers = ({isLoading, annonces}) => {
  
  if(isLoading)  return <Loading loadingStyle={null} style={{
    padding: '75px 0',
    height : '100%'
  }}/>

  return (
    
    <ul
    data-view="list-view"
    data-bs-toggle="regular-products"
    className="products products list-unstyled row g-0 row-cols-2 row-cols-md-3 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-5"
  >
    {
      annonces && annonces?.map((item) => (
        <Annonce info={item} />
      ))
    }
  </ul>
  );
};

export default AnnouncementsAndOffers;
