import React, {useEffect} from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Loading} from '../components'
import { axiosClient } from '../constants/axionsClient'
import { dashboardRoute } from '../constants/env'

const LoginWithToken = () => {
    const { t } = useTranslation()
    const {token} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        
        (async()=>{
            toast.loading(t('login_with_token.redirecting'))
            try {
                
                const res = await axiosClient.post('/loginSession', {
                    session_token : token
                })

                if(res.status == 200){

                    localStorage.setItem('user', JSON.stringify(res.data.user))
                    localStorage.setItem('token', res.data.access_token)
                    toast.dismiss()
                    navigate('/')

                }

            } catch (error) {
                
                toast.dismiss()
                toast.error(t('login_with_token.invalid_token'))
                console.log(error);
                window.location.href = dashboardRoute

            }

        })()

    }, [])
    

  return (
    <div>
        <Loading />
    </div>
  )
}

export default LoginWithToken