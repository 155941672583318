import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { isLogged, logout, redirectTo } from "../constants/AuthFunctions";
import { dashboardRoute } from "../constants/env";
import { useTranslation } from "react-i18next";

const TopBar = () => {

  const [auth, setAuth] = useState(isLogged());
  const { t } = useTranslation();

  useEffect(() => {
      setAuth(isLogged());
  }, []);

  const Logout = (e) => {
      e.preventDefault()
      logout(e)
  }


  return (
    <div className="top-bar top-bar-v4 bg-light border-0 hidden-lg-down d-none d-xl-block">
      <div className="container clearfix">
        <ul
          id="menu-top-bar-left"
          className="nav nav-inline float-start electro-animate-dropdown flip"
        >
          <li
            id="menu-item-3233"
            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3233"
          >
            <a
              title={t('topbar.site_description')}
              href="#"
            >
              {t('topbar.site_description')}
            </a>
          </li>
        </ul>
        <ul
          id="menu-top-bar-right"
          className="nav nav-inline float-end electro-animate-dropdown flip"
        >
          {
            auth ? (
              <>
                <li 
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                  onClick={(e) => redirectTo(e)}
                >
                  <a href="#">
                    {t('topbar.dashboard')}
                  </a>
                </li>
                <li 
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                  onClick={(e) => Logout(e)}
                >
                  <a href="#">
                    {t('topbar.logout')}
                  </a>
                </li>
              </>
            )

            :

            (
              <>
                <li
                  id="menu-item-5413"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                >
                  <Link title={t('topbar.login')} to="/auth/login">
                    {t('topbar.login')}
                  </Link>
                </li>
                <li
                  id="menu-item-5414"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5414"
                >
                  <a
                    title={t('topbar.join_free')}
                    href={`${dashboardRoute}/register`}
                  >
                    {t('topbar.join_free')}
                  </a>
                </li>
              </>
            )
          }
        </ul>{" "}
      </div>
    </div>
  );
};

export default TopBar;
