import React from 'react'
import { useTranslation } from 'react-i18next'
import { bg, logo } from '../../constants/images'

const HeaderInfo = ({information, TopProductsCount, contactFournisseur}) => {
    const { t } = useTranslation();

    return (
            <div className="container store-v1" style={{ zIndex: 0, position: "relative" }}>
                <div className="site-content-inner">
                    <div className="electro-dokan-single-store-header-wraper">
                        <div className="dokan-single-store-header">
                            <div className="profile-frame">

                                <div className="profile-info-box profile-layout-electro">
                                    
                                    <div className="bg-image-wrapper">
                                        <img 
                                            src={information.cover || bg}
                                            alt="" 
                                            title="" 
                                            className="profile-info-img" 
                                            id="entreprise-couverture" 
                                        />
                                    </div>

                                    <div className="profile-info-summery-wrapper dokan-clearfix">
                                        
                                        <div className="profile-info-summery">
                                            
                                            <div className="profile-info-head">
                                                <div className="profile-img profile-img-circle">
                                                    <img 
                                                        src={information?.photo || logo}
                                                        id="entreprise-logo" 
                                                        size="160" 
                                                        alt=""
                                                    />
                                                </div>
                                               
                                            </div>

                                            <div className="profile-summery-info-wrapper">
                                                <div className="title-and-rating">
                                                    <h1 className="store-name" id="entreprise-nom">{information?.name} {information?.is_valid ? <i title={t('header_info.validated')} className="fa-solid fa-circle-check" style={{color: 'green', fontSize : '23px'}}></i> : ''} </h1>
                                                </div> 
                                                {
                                                    TopProductsCount > 0 && (
                                                        <div className="profile-info">
                                                            <ul className="dokan-store-info">
                                                                <li className="dokan-sold-products">
                                                                    <span className="sold-products-count">
                                                                        <i className="fas fa-box"></i> {TopProductsCount} {t('header_info.products_count')}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div> 
                                                    )
                                                }
                                            </div> 

                                            <div className="dokan-store-support-and-follow-wrap">
                                                <div className="dokan-store-follow-store-button-container dokan-store-follow-store-btn-wrap">
                                                    <button 
                                                        onClick={(e) => contactFournisseur(e, information.id)} 
                                                        type="button" 
                                                        className="dokan-btn dokan-btn-theme dokan-follow-store-button dokan-btn-sm"
                                                    >
                                                        <span className="dokan-follow-store-button-label-current">{t('header_info.contact_supplier')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </div>

                                </div> 

                            </div> 
                            <div className="dokan-store-tabs">
                                <ul className="dokan-list-inline">
                                    <li className="products active"><a href="#presentation">{t('header_info.store_tabs.presentation')}</a></li>
                                    <li className="products"><a href="#produits">{t('header_info.store_tabs.products')}</a></li>
                                    <li className="reviews"><a href="#top-produits">{t('header_info.store_tabs.top_products')}</a></li>
                                    <li className="dokan-share-btn-wrap dokan-right"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

}

export default HeaderInfo