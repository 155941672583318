
const FilterSideBg = '/assets/images/b2bmaroc_pub.jpg'
const RecommendedAnnonceBg = '/assets/images/woocommerce-placeholder-300x300.png'
const AnnonceBg = '/assets/images/woocommerce-placeholder-300x300.png'
const HeaderBg = '/assets/images/logo.svg'
const FlagFrBg = '/assets/images/fr_FR.png'
const FlagUsBg = '/assets/images/en_US.png'
const FooterBg = '/assets/images/logo.svg'
const premiumImg = '/assets/images/premiumImg.jpeg'
const SideAnnonceDetails1Bg = '/assets/images/b2bmaroc_pub.jpg'
const SideAnnonceDetails2Bg = '/assets/images/b2bmaroc_pub-189x300.jpg'
const SideAnnonceDetails3Bg = '/assets/images/b2bmaroc_pub-8x12.jpg' 
const HeroBg = '/assets/images/hero.jpg'
const B2BLogo = '/assets/images/logo.svg'
const AvantagesBg = '/assets/images/Page-Avantages-de-vendre-sur-B2B-Maroc-scaled.jpg'
const PlansBg = '/assets/images/Page-Nos-Packs-Premium-1.jpg'
const Page_A_Propos = '/assets/images/Page-A-Propos.jpg'
const Experimental_Device_Set = '/assets/images/14-Experimental-Device-Set-Mockup-scaled.jpg'
const recrutement = '/assets/images/Page-recrutement.jpg'
const aide_bg = '/assets/images/Page-FAQ-et-Aide.jpg'
const blogs_bg = '/assets/images/bg_blogs.png'
const loginImg = "/assets/images/undraw_sign_in_re_o58h.svg"

const bg = 'https://i.ibb.co/8dm24sg/bg-12.jpg';
const logo = 'https://i.ibb.co/9vq1NDK/Screenshot-2024-11-05-134141.png';
const product = 'https://i.ibb.co/mcPtsxP/woocommerce-placeholder-300x300.png';

export {
    loginImg,
    bg,
    logo,
    product,
    blogs_bg,
    aide_bg,
    premiumImg,
    B2BLogo,
    FilterSideBg,
    RecommendedAnnonceBg,
    AnnonceBg,
    HeaderBg,
    FlagFrBg,
    FlagUsBg,
    FooterBg,
    HeroBg,
    SideAnnonceDetails1Bg,
    SideAnnonceDetails2Bg,
    SideAnnonceDetails3Bg,
    AvantagesBg,
    PlansBg,
    Page_A_Propos,
    Experimental_Device_Set,
    recrutement
}